import React, {useState, useEffect} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import {Link, navigate , useIntl } from "gatsby-plugin-intl"
import {Modal} from "react-bootstrap";
import imgVehicle from "../../assets/images/vehicle/JUKE_F15-A1-min.png";
import axios from "../../components/api/httpclient";

class BodyClass extends React.Component {

  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const Settings = () => {

  const [classCss, setClassCss] = useState('mb-0 py-4 w-100');
  const [modalShow, setModaleShow] = useState(false);

  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};

  const intl = useIntl();

  useEffect(() => {
    if(!window.location.href.includes("-BE")) {
      setClassCss('d-none');
    }
  }, []);

  const handleClose = () => {
    setModaleShow(false)
  }

  const handleShow = () => {
    setModaleShow(true)
  }

  const removeAccount = (event) => {
    axios.post('/remove-account',
      {userId: currentUser.id},
      {
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      }).then((response) => {
        navigate('/home');
      }
    );
    event.preventDefault();
  }

  const pageContent = (
      <div className="col-lg-8">

        <div className="white-container mb-4">

          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({id: "my_preferences.my_settings"})}</h1>


          <ul className="list-link-picto light-grey-simple-border">

            <li className={classCss}><Link to="/profile/edit-language"><span className="icon-globe icon dark-red"></span><h3 className="mb-0">{intl.formatMessage({id: "my_preferences.language_preferences"})}</h3></Link></li>

            <li className="mb-0 py-4 w-100"><Link to="/sessions/logout"><span className="icon-logout-2 icon dark-red fs-20"></span><h3 className="mb-0">{intl.formatMessage({id: "my_preferences.logout"})}</h3></Link></li>
          </ul>


        </div>

        <div className="transparent-container mb-4">

          <h2>{intl.formatMessage({id: "my_preferences.delete_account_question"})}</h2>
          <p>{intl.formatMessage({id: "my_preferences.delete_account_text_1"})}
            <a href="#" onClick={() =>handleShow()}>{intl.formatMessage({id: "my_preferences.delete_account_text_2"})}</a>
            {intl.formatMessage({id: "my_preferences.delete_account_text_3"})}</p>
        </div>

        <Modal size="lg" show={modalShow} onHide={() => handleClose()} animation={false}>
          <Modal.Header className="justify-content-center">
            <h2 className="modal-title text-uppercase flex-grow-1 text-center" id="exampleModalCenterTitle">
              {intl.formatMessage({ id: "confirm_popin.2.header" })}
            </h2>
            <button type="button" className="close" onClick={() => handleClose()}>
                  <span aria-hidden="true">
                    <span className="icon-close"></span>
                  </span>
            </button>
          </Modal.Header>
          <Modal.Body className="text-center">
            <h2>{intl.formatMessage({ id: "confirm_popin.2.title" })}</h2>

            <p>{intl.formatMessage({ id: "confirm_popin.2.description" })}</p>
          </Modal.Body>
          <Modal.Footer className="text-center d-block d-md-flex">
            <button type="button" className="button red mb-4 mb-md-0" onClick={(event) => removeAccount(event)}><span>{intl.formatMessage({ id: "generic.forms.form_buttons.confirm_deletion" })}</span></button>
            <button type="button" className="button white" onClick={() => handleClose()}>
              {intl.formatMessage({ id: "generic.forms.form_buttons.cancel" })}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )


  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.settings" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent}  />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )

}

export default Settings
