import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


import MediaLayout from "../../components/media-layout"
import moment from "moment";
import {useIntl, Link} from "gatsby-plugin-intl"

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const EditInfo = () => {
  const intl = useIntl();

  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};
  const [displayPopin, setdisplayPopin] = useState(typeof window !== 'undefined' ? localStorage.getItem('popin') : false);

  
  const userGender = intl.formatMessage({ id: "generic.forms.form_options.user_title."+currentUser.gender });

  const [seconds, setSeconds] = React.useState(5);

  React.useEffect(() => {
    if (displayPopin == 'true'){
      if (seconds > 0){
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        localStorage.removeItem('popin');
      }
    }
  });

  const popin = (display) => {
    if(display == 'true'){
      return (
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          {intl.formatMessage({id: "my_profil.info_update"})}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    }
  }

  const pageContent = () => {
    return(
      <div className="col-lg-8">
        {/* {popin(displayPopin ? displayPopin : false)} */}
        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({id: "my_profil.page_title"})}</h1>
          <div className="row">
            <div className="col-md-6 mb-4 pl-md-5">
              <div className="form-group">
                <label className="form-label">{intl.formatMessage({id: "my_profil.block1_title"})}</label><Link to="/profile/edit-info-2" className="dark-red text-decoration-none"><span className="icon-pencil ml-2"></span></Link>
                <br/>
                <br/>
                <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.user_title"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{userGender}</strong></div>
                <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.first_name"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.firstName}</strong></div>
                {/* <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.particle_name"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.particleName}</strong></div> */}
                <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.last_name"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.lastName}</strong></div>
                {(() => {
                  if(currentUser.birthDate) {
                    return (
                        <div>
                          <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.date_of_birth"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{moment(currentUser.birthDate, moment.ISO_8601).format('DD/MM/YYYY')}</strong></div>
                        </div>
                    )
                  }
                })()}
              {(() => {
                  if(currentUser.phone) {
                    return (
                        <div>
                          <div className="d-inline-block w-50 dark-grey phone-number">{intl.formatMessage({id: "generic.forms.form_field_labels.phone"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.phone}</strong></div>
                        </div>
                    )
                  }
                })()}

                {/* ADDRESS */}
                {/*
                <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.house_number"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.houseNumber}</strong></div>
                <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.house_number_ext"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.houseNumberExt}</strong></div>
                <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.street_name"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.streetName}</strong></div>
                <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.postal_code"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.postalCode}</strong></div>
                <div className="d-inline-block w-50 dark-grey">{intl.formatMessage({id: "generic.forms.form_field_labels.city"})}</div><div className="d-inline-block w-50 text"><strong className="dark-red">{currentUser.city}</strong></div>
                */}
              </div>



            </div>

            <div className="col-md-6 mb-4">


              <div className="form-group">
                <label className="form-label">{intl.formatMessage({id: "generic.forms.form_field_labels.edit_my_email"})}</label>
                <br/>{currentUser.email}<Link to="/profile/change-email" className="dark-red text-decoration-none"><span className="icon-pencil ml-2"></span></Link><br/><br/>
              </div>

              <div className="form-group">
                <label className="form-label">{intl.formatMessage({id: "generic.forms.form_field_labels.edit_my_password"})}</label>
                <Link to="/profile/change-password" className="button red"><span>{intl.formatMessage({id: "generic.forms.form_buttons.change_password"})}</span></Link>
              </div>

            </div>

          </div>

        </div>
      </div>
    )
  }

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.edit-info" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="edit-info" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default EditInfo
