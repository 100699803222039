import React from "react"

import BarStep from "./partials/bar-step"
import ChooseDealer from "../dealer/choose-dealer"
import Layout from "../../components/layout";

const AddDealer = () => {
  const checked = ["step1"];

  return (
    <div>
      <Layout>
        <BarStep selected="step2" checked={checked} />
        <ChooseDealer navigateTo='/profile/congratulations-car-added'/>
      </Layout>
    </div>
  )
};


export default AddDealer
