import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Form } from "react-bootstrap"
import instance  from "../../components/api/httpclient"
import { navigate, useIntl, Link } from "gatsby-plugin-intl"

const PasswordResetNew = () => {
  
  let paramToken = '';
  if (typeof window !== 'undefined') {

    const getUrlParameter = (name) => {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      let results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    paramToken = getUrlParameter('token');
  }

  const [token, setToken] = useState(paramToken)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);
  const intl = useIntl();



  const validatePassword = (password) => {
    var re = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*).{8,}/g;
    return re.test(password);
  }

  /* Toggle password visibility */
  const togglePwd = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleClick = (event) => {
    event.preventDefault();

    // Check if password is valid
    if(password === "" || !validatePassword(password)) {
      setErrorPassword(true);
    }
    else {
      const resp = instance.post(`/change-password`,
        {
          token: token,
          password: password,
        }).then(function (response) {
            console.log(response);
            navigate('/sessions/password-reset-confirm');
      });

    }

  };

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.sessions.login" })} />

      <div className="white-container mb-5">

        <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "1_1_3_login_forgot_set_new_password.title" })}</h1>

        <p className="mb-4">{intl.formatMessage({ id: "1_1_3_login_forgot_set_new_password.error" })}</p>

        <Form onSubmit={(event) => handleClick(event)}>

          <div className="row">

            <div className="col-md-8">

              <Form.Group>
                <Form.Label className="required">{intl.formatMessage({ id: "generic.forms.form_field_labels.password" })}</Form.Label>
                <div className="password-wrapper">
                  {isPasswordVisible ?
                    <input
                      className={errorPassword ? "error form-control" : "form-control"}
                      type='text'
                      onChange={
                        (e) => {
                          setPassword(e.target.value);
                          e.target.value === "" ? setErrorPassword(true) : setErrorPassword(false);
                        }
                      }
                      value={password}
                    />
                    :
                    <input
                      className={errorPassword ? "error form-control" : "form-control"}
                      type='password'
                      onChange={
                        (e) => {
                          setPassword(e.target.value);
                          e.target.value === "" ? setErrorPassword(true) : setErrorPassword(false);
                        }
                      }
                      value={password}
                    />}

                  <span className={isPasswordVisible ? "icon-eye toggle-pwd grey-border" : "icon-eye toggle-pwd"} onClick={togglePwd.bind()}></span>
                </div>
                { errorPassword &&
                <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({ id: "generic.forms.form_error_message.error_message_3" })}</small>
                }
              </Form.Group>

              <div className="required-field">*{intl.formatMessage({ id: "generic.forms.form_mentions.required_fields" })}</div>
            </div>

            <div className="col-md-4 pt-label">
              <button type="submit" className="button red w-100 mx-auto">{intl.formatMessage({ id: "generic.forms.form_buttons.submit" })}</button>
            </div>

          </div>

        </Form>
      </div>

    </Layout>
  );
}

export default PasswordResetNew
