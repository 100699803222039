import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faCalculator } from '@fortawesome/free-solid-svg-icons'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import {Link, navigate, useIntl} from "gatsby-plugin-intl";
// import logoSender from "../../assets/images/logo.png";
// import moment from "moment/moment";

class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const SellCarPage = ({location}) => {
  const intl = useIntl();
  if (!location.state || !location.state.id) {
    navigate('/maintenance/timeline');
  }

  const pict1 = <FontAwesomeIcon icon={faCalculator} />
  const pict2 = <FontAwesomeIcon icon={faUpload} />
  const pageContent = () => {
    return(
      <div className="col-lg-8">

        <a href="#" onClick={(e) => { e.preventDefault(); window.history.back(); }} className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</strong></a>
        {/*<Link to={"/maintenance/timeline"} className="black mb-4 back-link"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</strong></Link>*/}

        <div className="white-container d-flex flex-column">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">Je vends mon véhicule</h1>

          <div className="text-center">

            <a href="https://nissan-occasions.fr/fr/nos-avantages/trade-in" target="_blank" className="button red mx-auto with-icon"><i className="icon">{pict1}</i><span>{intl.formatMessage({ id: "timeline.buttons.button_estimate_vehicle" })}</span></a>

            <p className="font-weight-bold my-3">OU</p>

            <Link to="/mycars/transfer-ecarnet" state={{id: location.state ? location.state.id : ""}} className="button red mx-auto with-icon mb-4"><i className="icon">{pict2}</i><span className="text-wrap-balance">{intl.formatMessage({ id: "timeline.buttons.button_transfer_ecarnet" })}</span></Link>

            <p className="font-weight-bold">Cela permettra au nouveau propriétaire d'ajouter ce véhicule dans son compte Nissan le Club</p>

          </div>
        </div>

      </div>
    )
  };

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.mycars.add-car" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="my-cars" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
};

export default SellCarPage
