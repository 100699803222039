import React, {useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


import MediaLayout from "../../components/media-layout";
import {useIntl, Link} from "gatsby-plugin-intl";
import {navigate} from 'gatsby';
import {Form} from "react-bootstrap";
import instance from "../../components/api/httpclient";

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const ChangeEmail = () => {
  const intl = useIntl();
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailInUse, setErrorEmailInUse] = useState(false);
  const [email, setEmail] = useState('');

  const [errorEmailConfirm, setErrorEmailConfirm] = useState(false);
  const [errorEmailConfirmMatch, setErrorEmailConfirmMatch] = useState(false);
  const [emailConfirm, setEmailConfirm] = useState('');

  const validateEmail = (email) => {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  const handleClick = (event) => {

    // Check if email is empty and not valid
    if(email === "" || !validateEmail(email)) {
      setErrorEmail(true);
    }
    else {
      setErrorEmail(false);
    }

    // Check if emailConfirm is empty and not valid
    if(emailConfirm === "" || !validateEmail(emailConfirm)) {
      setErrorEmailConfirm(true);
    }
    else {
      setErrorEmailConfirm(false);
    }

    // Check if email and emailConfirm match
    if(email !== emailConfirm) {
      setErrorEmailConfirmMatch(true);
    }
    else {
      setErrorEmailConfirmMatch(false);
    }

    if(!errorEmail && !errorEmailConfirm && !errorEmailConfirmMatch) {
      instance.post('/check-email',
        {
          email: emailConfirm
        },
        {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        }).then(function (response) {
        if (response.status === 200) {
          console.log(response)
          if (!response.data.valid) {
            setErrorEmailInUse(true);
          }


          if (!errorEmail && !errorEmailInUse && !errorEmailConfirm && !errorEmailConfirmMatch) {
            instance.post(`/change-email`,
              {
                id:currentUser.id,
                email: emailConfirm
              },
              {
                headers: {
                  'Authorization': localStorage.getItem('token')
                }
              }).then(function (res) {

                if(res.status === 200 && res.data.token !== null) {
                  if (typeof window !== 'undefined') {
                    localStorage.setItem('token', 'Bearer ' + res.data.token);

                    // UPDATING LOCALSTORAGE
                    instance.get("/me",
                      {
                        headers: {
                          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                        }
                      })
                      .then((r) => {
                        if (typeof window !== 'undefined') {
                          localStorage.setItem('currentUser', JSON.stringify(r.data.user));

                          // SENDING MAIL
                          instance.post(`/email-changed-email`,
                            {
                              email: emailConfirm,
                              first_name: currentUser.firstName,
                              particle_name: currentUser.particle_name,
                              last_name: currentUser.lastName,
                              lang: intl.locale
                            },
                            {
                              headers: {
                                'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                              }
                            }).then(function (resp) {
                              console.log(resp);
                          });
                          localStorage.setItem('popin', true);
                          navigate('/profile/edit-info');
                        }
                      });

                  }
                }

              });
          }

        } else if (response.status === 404) {
          console.log('error', '404');
        } else if (response.status === 401) {
          navigate('/sessions/logout');
        }
      }).catch((error) => {
          console.log(error)
          console.log('Unable to check')
        }
      );
    }

  };

  const pageContent = () => {
    return(
      <div className="col-lg-8">
        <div className="mobile-container">
          <Link to="/profile/edit-info" className="black mb-4 mt-0 back-link"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({id: "generic.nav_buttons.back_button"})}</strong></Link>
        </div>
        <div className="white-container">

          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({id: "generic.forms.form_field_labels.edit_my_email"})}</h1>


          <div className="row">

            <div className="col-md-8 mb-4">

              <div className="form-group">
                <Form.Group>
                  <Form.Label className="required">{intl.formatMessage({id: "generic.forms.form_field_labels.email"})}</Form.Label>

                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      e.target.value === '' ? setErrorEmail(true) : setErrorEmail(false);
                      email !== emailConfirm ? setErrorEmailConfirmMatch(true) : setErrorEmailConfirmMatch(false);
                    }}
                    value={email}
                    className={(errorEmail) ? 'error' : ''}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required">{intl.formatMessage({id: "generic.forms.form_field_labels.email_confirmation"})}</Form.Label>

                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      setEmailConfirm(e.target.value);
                      e.target.value === '' ? setErrorEmailConfirm(true) : setErrorEmailConfirm(false);
                      email !== emailConfirm ? setErrorEmailConfirmMatch(true) : setErrorEmailConfirmMatch(false);
                    }}
                    value={emailConfirm}
                    className={(errorEmailConfirm) ? 'error' : ''}
                  />
                </Form.Group>
              </div>
              {errorEmail ?

                <div className="col-md-8">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({id: "generic.forms.form_error_message.error_message_1"})}</small>
                  </Form.Group>
                </div>
                :
                ""
              }
              {errorEmailInUse ?

                <div className="col-md-8">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({id: "generic.forms.form_error_message.error_message_9"})}</small>
                  </Form.Group>
                </div>
              :
                ""
              }
              {errorEmailConfirm ?

                <div className="col-md-8">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({id: "generic.forms.form_error_message.error_message_1"})}</small>
                  </Form.Group>
                </div>
                :
                ""
              }
              {errorEmailConfirmMatch ?

                <div className="col-md-8">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({id: "generic.forms.form_error_message.error_message_8"})}</small>
                  </Form.Group>
                </div>
                :
                ""
              }

              <div className="form-group">
                <div className="button red mx-auto mr-md-0" onClick={(event) => handleClick(event)} >{intl.formatMessage({id: "generic.forms.form_buttons.update"})}</div>

              </div>

            </div>

          </div>

        </div>
      </div>
    )
  }

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.change-email" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="change-email" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default ChangeEmail
