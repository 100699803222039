import axios from 'axios';
require("dotenv").config({
    path: `.env.${process.env.NODE_ENV}`,
})
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        Accept:         'application/ld+json',
        'Content-Type': 'application/json',
    },
});

export default instance;
