import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Step1 from './signup-steps/step1'
import Step2 from './signup-steps/step2'
import Step3 from './signup-steps/step3'
import { FormattedMessage } from "gatsby-plugin-intl"

class Signup extends React.Component {
  constructor(options) {
    super(options);
    this.state = {
      step: 1,
      gender: '',
      first_name: '',
      particle_name: '',
      last_name: '',
      birthday: null,
      email: '',
      stepEmail: '',
      language: '',
      country: '',
      streetName: '',
      houseNumber: '',
      houseNumberExt: '',
      postalCode: '',
      city: '',
      isPasswordVisible : false,
      password: '',
      optin: false,
      account_exists: false,
      nextButtonDisabled: true,
      submitButtonDisabled: false,
      showMoreOpen: false,
      infoOpen: false,
      formErrors: {},
      source:'',
      medium:'',
      campaign:''};
  }

  handleChangeStep1(event) {
    var _state = {};
    _state[event.target.name] = event.target.value;
    this.setState(_state, () => {
      this.formValidation();
    });
  }

  handleChangeStep2(event) {
    var _state = {};
    _state[event.target.name] = event.target.value;
    this.setState(_state);
  }

  genericChange(params) {
    this.setState(params, () => {
      this.formValidation();
    });
  }

  datePickerChange(date) {
    this.setState({
      birthday: date
    })
  }

  //selectChange(event) {
    //let index = event.nativeEvent.target.selectedIndex;
    // this.setState({country: event.target.value, customSelect: event.nativeEvent.target[index].text});
  //}

  nextStep(event) {
    event.preventDefault();
    if (!this.formValidation()) {
      this.setState({step: this.state.step + 1});
      console.log("STATE :", this.state)
      window.scrollTo(0, 0);
    }
  }

  validateEmail(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*(?:\+[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  validatePassword(password) {
    var re = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*).{8,}/g;
    return re.test(password);
  }

  formValidation() {
    let error = false;
    let _state = {};

    if (1 === this.state.step) {
      if(this.state.first_name === '') {
        _state['first_name'] = 'error';
        error = true;
      } else {
        _state['first_name'] = '';
      }
      if(this.state.last_name === '') {
        _state['last_name'] = 'error';
        error = true;
      } else {
        _state['last_name'] = '';
      }
      if(this.state.gender === '') {
        _state['gender'] = 'error';
        error = true;
      } else {
        _state['gender'] = '';
      }
      if(this.state.birthday === '') {
        _state['birthDate'] = {};
        error = false;
      } else {
        _state['birthDate'] = '';
      }
      if(this.state.optin === false) {
        _state['optin'] = 'error';
        error = true;
      } else {
        _state['optin'] = '';
      }
    } else if (2 === this.state.step) {
      // if(this.state.street_name === '' || this.state.street_name === undefined) {
      //   _state['street_name'] = 'error';
      //   error = true;
      // } else {
      //   _state['street_name'] = '';
      // }
      // if(this.state.house_number === '' || this.state.house_number === undefined) {
      //   _state['house_number'] = 'error';
      //   error = true;
      // } else {
      //   _state['house_number'] = '';
      // }
      // if(this.state.postal_code === '' || this.state.postal_code === undefined) {
      //   _state['postal_code'] = 'error';
      //   error = true;
      // } else {
      //   _state['postal_code'] = '';
      // }
      // if(this.state.city === '' || this.state.city === undefined) {
      //   _state['city'] = 'error';
      //   error = true;
      // } else {
      //   _state['city'] = '';
      // }
    } else if (3 === this.state.step) {
      if(!this.validateEmail(this.state.email)) {
        _state['email'] = 'error';
        error = true;
      } else {
        _state['email'] = '';
      }
      if(!this.validatePassword(this.state.password)) {
        _state['password'] = 'error';
        error = true;
      } else {
        _state['password'] = '';
      }
      if(this.state.account_exists === true) {
        _state['account_exists'] = 'error';
        error = true;
      } else {
        _state['account_exists'] = '';
      }
    }

    if (error === true) {
      this.setState({nextButtonDisabled: true})
    } else {
      this.setState({nextButtonDisabled: false})
    }

    this.setState({formErrors: _state});
    return error;
  }

  render () {
    return (
    <Layout>
      <SEO title="Sign Up" />

      {(() => {
        switch (this.state.step) {
          case 1:
            return (<Step1 nextStep={this.nextStep.bind(this)} genericChange={this.genericChange.bind(this)} handleChange={this.handleChangeStep1.bind(this)} state={this.state} setState={this.setState.bind(this)}/>);
          case 2:
            return (<Step2 nextStep={this.nextStep.bind(this)} genericChange={this.genericChange.bind(this)} handleChange={this.handleChangeStep1.bind(this)} state={this.state} setState={this.setState.bind(this)} />);
          case 3:
            return (<Step3 nextStep={this.nextStep.bind(this)} handleChange={this.handleChangeStep2.bind(this)} state={this.state} formValidation={this.formValidation.bind(this)} />);
          default:
            return (<Step1 nextStep={this.nextStep.bind(this)} genericChange={this.genericChange.bind(this)} handleChange={this.handleChange.bind(this)} state={this.state} />);
        }
      })()}


      <p className={this.state.showMoreOpen ? "show-more open" : "show-more"}>
        <FormattedMessage id="generic.forms.form_mentions.gdpr_text" />
      </p>

      <div className="text-center mb-4">
        <div className={this.state.showMoreOpen ? "btn-show-more open" : "btn-show-more"} onClick={() => this.setState({showMoreOpen: !this.state.showMoreOpen})}><FormattedMessage id="generic.forms.form_buttons.readmore" /></div>
      </div>

  </Layout>
    )
  }
}


export default Signup
