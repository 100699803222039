import React from "react"

import Layout from "../../components/layout"

const InvitationCanceled = () => {
  return (
    <Layout>
      <div className="white-container mb-5">
        <h1 className="text-center">
          Transfert du e-Carnet annulé
        </h1>

        <p className="text-center mb-4">Le propriétaire du véhicule a annulé le transfert du e-Carnet vers votre compte.</p>
        <p className="text-center mb-4">N’hésitez pas à nous contacter si besoin <a href="https://www.nissan.fr/service-clients/contact/poser-une-question.html">via ce lien</a></p>
      </div>

    </Layout>
  )
}

export default InvitationCanceled

