import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useIntl, Link, navigate, changeLocale} from "gatsby-plugin-intl"
import { Helmet } from 'react-helmet'
import {Modal} from "react-bootstrap";
import Footer from "./footer"
import Legal from "../pages/legal"
import Cookies from "../pages/cookies"
import jwt_decode from "jwt-decode"
import {canIBeHere, getUser} from "../services/auth";
import ENV from "../../env";
import firebase from '../notification/firebase';
import logo from "../assets/images/logo.png";


const Layout = ({ children }) => {
  const intl = useIntl();
  const [modalShow, setModaleShow] = useState(null);
  const url = typeof window !== 'undefined' ? window.location.href : '';

  useEffect(() => {
    let fullToken = localStorage.getItem('token');
    let user = getUser();
    if (user.language !== intl.locale) {
      if (!!user.email) {
        changeLocale(user.language);
      }
    }
    if (fullToken !== null) {
      let token = fullToken.replace('Bearer ','');
      let decoded = jwt_decode(token);
      let exp = decoded.exp;
      const now = new Date();
      const nowInSeconds = Math.round(now.getTime() / 1000);
      if (nowInSeconds >= exp) {
        navigate('/sessions/logout');
      }
    }
    //Appel à l'api de firebase pour obtenir le token du device
    if(firebase.messaging.isSupported())
    {
      const messaging = firebase.messaging();
    messaging.requestPermission().then(()=>{
      return messaging.getToken()
    }).then(token=>{
      console.log('Token : ', token)
    }).catch(()=>{
      console.log('error');
    })
    }

  }, []);

  let parts = url.split('/');
  let currentPage = parts[parts.length - 1];
  if (currentPage === "" || currentPage.startsWith('?')) {
    currentPage = parts[parts.length - 2];
  }






  const handleClose = () => {
    setModaleShow(null);
  };

  const toggleModale = (e, target) => {
    e.preventDefault();
    setModaleShow(target)
  }

  const mainContent = () => {
    if(window.location.pathname.split('/')[2] === "home" ) {
      return (
        <>
          <main>{children}
          <footer className="d-flex justify-content-around d-md-block text-center footer">
          <a href="https://www.nissan.fr/service-clients/contact/poser-une-question.html" target="_blank" className="mx-md-4">{intl.formatMessage({ id: "nissan_footer.menu1.subitems.3.label" })}</a>
          <a href="https://www.nissan.fr/gdpr.html" target="_blank" className="mx-md-4">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.6.label" })}</a>
          <Link to="#" className="mb-2 mx-md-4" onClick={(e) => toggleModale(e, 'Cookies')}>{intl.formatMessage({ id: "nissan_footer.other_links.subitems.7.label" })}</Link>
          <Link to="#" className="mb-2 mx-md-4" onClick={(e) => toggleModale(e, 'Legal')}>{intl.formatMessage({id: "pages_meta_title.legal"})}</Link>
          </footer>
          </main>
        </>
      )

    } else {
      return (
        <div className="wrapper-nn">
          <div className="container">
            <div className="text-center mb-4">
              <a href="https://www.nissan.fr/" target="_blank">
              <img src={logo} alt="logo" className="logo" />
              </a>
            </div>
            <main>{children}</main>
            <footer className="d-flex flex-column justify-content-around d-md-block text-center footer">
              <a href="https://www.nissan.fr/service-clients/contact/poser-une-question.html" target="_blank" className="mx-md-4">{intl.formatMessage({ id: "nissan_footer.menu1.subitems.3.label" })}</a>
              <a href="https://www.nissan.fr/gdpr.html" target="_blank" className="mx-md-4">{intl.formatMessage({ id: "nissan_footer.other_links.subitems.6.label" })}</a>
              <Link to="#" className="mb-2 mx-md-4" onClick={(e) => toggleModale(e, 'Cookies')}>{intl.formatMessage({ id: "nissan_footer.other_links.subitems.7.label" })}</Link>
              <Link to="#" className="mb-2 mx-md-4" onClick={(e) => toggleModale(e, 'Legal')}>{intl.formatMessage({id: "pages_meta_title.legal"})}</Link>
            </footer>
          </div>
        </div>
      )
    }
  }

  if (false === canIBeHere(currentPage)) {
    // navigate('/sessions/login');
    return null;
  } else {
    return (
      <>
        <Helmet>
          <link rel="stylesheet" media="print, screen"
                href={"//www-europe.nissan-cdn.net/content/dam/Nissan/header-footer/" + intl.locale + "/css/PACE-header-footer-v3.min.css"}/>
          <script
            src={"//www-europe.nissan-cdn.net/content/dam/Nissan/header-footer/" + intl.locale + "/js/PACE-header-footer-v3.min.js"}
            className="heliosinclude"></script>



        </Helmet>
       {mainContent()}

        {/*<div id="nissan_global_footer" className="helios"></div>*/}
        <Footer/>
        <Modal size="lg" centered show={modalShow === 'Legal' || modalShow === 'Cookies'} onHide={() => handleClose()} animation={false} id="legals">
          <Modal.Header className="justify-content-center">
            <h1 className="modal-title text-uppercase flex-grow-1 text-center mt-0" id="exampleModalCenterTitle">
              {modalShow === 'Legal' && intl.formatMessage({ id: "legals.preambule" })}
              {modalShow === 'Cookies' && intl.formatMessage({ id: "cookies.title" })}
            </h1>
            <button type="button" className="close" onClick={() => handleClose()}>
                  <span aria-hidden="true">
                    <span className="icon-close"></span>
                  </span>
                </button>
          </Modal.Header>
          <Modal.Body className="text-center px-4 pt-4 pb-4 " >
            {modalShow === 'Legal' && <Legal />}
            {modalShow === 'Cookies' &&  <Cookies handleClose={handleClose} />}
            <button class="button red mx-auto mr-md-0" onClick={() => handleClose()}>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</button>
          </Modal.Body>
        </Modal>

        <script
          src={"//www-europe.nissan-cdn.net/content/dam/Nissan/header-footer/" + intl.locale + "/js/PACE-header-footer-v3.min.js"}
          className="heliosinclude"></script>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
