import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const OnBoardingStart = () => {
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};
  const intl = useIntl();

  const statusColor = (() => {
    switch(currentUser.status) {
      case 1:
        return 'bronze';
      case 2:
        return 'silver';
      case 3:
        return 'gold';
      case 4:
        return 'platinium';
    }
  })()

  return (
    <Layout>

      <SEO title={intl.formatMessage({ id: "pages_meta_title.sessions.onboarding-start" })} />

      <div className="white-container mb-5">


        <div className="text-center mb-4">

          <span className="icon-win"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>

        </div>


        <h1 className="text-center"><FormattedMessage id="1_2_0_onboarding_start.title" /> 
        {/* <span className={statusColor}><FormattedMessage id="1_2_0_onboarding_start.level" values={{ level : currentUser ? intl.formatMessage({ id: "generic.status_level."+currentUser.status }) : "" }}/></span> */}
        </h1>

        <p className="text-center mb-4"><FormattedMessage id="1_2_0_onboarding_start.description" /></p>

        <p className="text-center mb-4"><FormattedMessage id="1_2_0_onboarding_start.ready" /></p>


        <div className="">
          <Link to="/profile/add-car" className="button red mx-auto"><span><FormattedMessage id="generic.forms.form_buttons.add_my_nissan" /></span></Link>
        </div>

      </div>

    </Layout>
  )
}

export default OnBoardingStart

