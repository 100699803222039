import React from "react"
import SEO from "../components/seo"
import {navigate, useIntl, Link} from "gatsby-plugin-intl";

const Legal = () => {
    const intl = useIntl();
    return (
        <>
          <SEO title="LEGAL NOTICE" />

            <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "legals.preambule" })}</h2>

            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text1" })}} />

            <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "legals.eligibilite" })}</h2>

            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text2" })}} />

            <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "legals.programme" })}</h2>

            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text3" })}} />

            <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "legals.evoluer.title" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.evoluer.text1" })}} />

             <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "legals.profiter.title" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.profiter.text1" })}} />

            <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "legals.suivi" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text4" })}} />

            <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "legals.taxes" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text5" })}} />

            <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "legals.garantie" })}</h2>
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text6" })}} />
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text7" })}} />
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text8" })}} />
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text9" })}} />
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text10" })}} />
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text11" })}} />
            <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "legals.text12" })}} />
        </>
    )
}

export default Legal
