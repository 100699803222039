import React from "react"
import {Link, useIntl } from "gatsby-plugin-intl"
import classNames from 'classnames/bind';

const BarStep = ({selected, checked = []}) => {
  const intl = useIntl();

  const step1Class = classNames({
    'active': selected === "step1",
    checked: checked.filter(step => step === "step1").length === 1
  });

  const step2Class = classNames({
    'active': selected === "step2",
    checked: checked.filter(step => step === "step2").length === 1
  });

  const step3Class = classNames({
    'active': selected === "step3",
    checked: checked.filter(step => step === "step3").length === 1
  });

  return (
    <div className="bar-step">
      <Link to="#" className={step1Class}>
        <span className="icon-car icon"></span>
        <span className="d-none d-md-block text">{intl.formatMessage({id: "onboarding_breadcrumb.step1"})}</span>
      </Link>
      <Link to="#" className={step2Class}>
        <span className="icon-dealer-favorite icon"></span>
        <span className="d-none d-md-block text text-dealer-favorite">{intl.formatMessage({id: "onboarding_breadcrumb.step2"})}</span>
      </Link>
    </div>
  )
}

export default BarStep