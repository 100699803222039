import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MediaLayout from "../../components/media-layout"
import { useMediaQuery } from 'react-responsive'
import instance from "../../components/api/httpclient";
import { navigate, useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import moment from "moment";
import ENV from "../../../env";
import Legal from "../legal"
import { Modal } from "react-bootstrap";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};

class BodyClass extends React.Component {

  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const OfferSingle = ({ location }) => {
  const [offer, setOffer] = useState({});
  const [image, setImage] = useState({});
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : "";
  const currentDealer = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentDealer')) : "";
  const intl = useIntl();

  const [modalShow, setModaleShow] = useState(false);
  const handleClose = () => {
    setModaleShow(false);
  };
  const toggleModale = (e) => {
    e.preventDefault();
    setModaleShow(true)
  };

  useEffect(() => {
    if (location) {
      if (location.state) {

        instance.get("/offers/" + location.state.id,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            setOffer(response.data);
            instance.get(response.data.thumbnail,
              {
                headers: {
                  'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                }
              })
              .then(function (res) {
                if (undefined !== res.data.contentUrl) {
                  setImage(process.env.API_URL + res.data.contentUrl)
                }
              }
              );
          }).catch((error) => {
            console.log('error', error);
          });
      }
    }
  }, []);


  const pageContent = () => {
    return (
      <div className="col-lg-8">
        <Link to="/offers/offers-list" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "offers.single.back" })}</Link>

        <div className="white-container">
          <div className="reward-single__img-wrapper mb-4" style={{ backgroundImage: "url(" + image + ")" }}>
          </div>
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{offer.detailTitle}</h1>
          <div className="text mb-2"><strong>{intl.formatMessage({ id: "offers.single.description" })}</strong></div>
          <p className="mb-4" dangerouslySetInnerHTML={{ __html: offer.detailDescription }}></p>

          <h3 className="text-uppercase"><span className="dark-red icon-ticket mr-2 "></span>{intl.formatMessage({ id: "offers.single.the_offer" })}</h3>

          <p className="mb-4" dangerouslySetInnerHTML={{ __html: offer.offerDescription }}></p>

          <div className="text mb-4"><strong>{intl.formatMessage({ id: "offers.single.date_due" })} {moment(offer.endDate, moment.ISO_8601).format('DD/MM/YYYY')}</strong>
            <Link to="#" className="d-block offer-single-link" onClick={(e) => toggleModale(e)}>{intl.formatMessage({ id: "footer.terms" })}</Link>
          </div>

          <Modal size="lg" centered show={modalShow} onHide={() => handleClose()} animation={false} id="legals">
            <Modal.Header className="justify-content-center">
              <h2 className="modal-title text-uppercase flex-grow-1 text-center" id="exampleModalCenterTitle">{intl.formatMessage({ id: "footer.terms" })}</h2>
              <button type="button" className="close" onClick={() => handleClose()}>
                <span aria-hidden="true">
                  <span className="icon-close"></span>
                </span>
              </button>
            </Modal.Header>
            <Modal.Body className="text-center px-4 pt-4 pb-4 " >
              <Legal />
              <button class="button red mx-auto mr-md-0" onClick={() => handleClose()}>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</button>
            </Modal.Body>
          </Modal>


          {/*<div className="mb-4">
            <div className="about-offer-bloc">
              <div className="d-flex get-quote mb-3">
                <a href="#" className="d-flex w-100 pb-3"><span className="icon icon-calcul-contract align-self-center"></span> <span className="text"><strong className="text-uppercase dark-red">Get a quote</strong><br />And book appointment</span></a>
              </div>
              <div className="d-flex links-contact">
                <a href="#"><span className="icon icon-pin-marker"></span> <span>Visit my<br />dealer</span></a>
                <a href="#"><span className="icon icon-phone"></span> <span>Call my<br />dealer</span></a>
              </div>
            </div>
          </div>*/}

          <div>
            <div className="">
              <div className="d-flex links-contact-go">
                {currentDealer.address &&
                  (
                    <a href={encodeURI("https://www.google.com/maps/search/" + currentDealer.name + ' ' + currentDealer.address + ' ' + currentDealer.postcode + ' ' + currentDealer.city)} target="_blank"><span className="icon icon-pin-marker"></span> <FormattedMessage id="offers.single.button_visit_dealer" values={{ lb: (...chunks) => <span><br />{chunks}</span> }}>{(...chunks) => <span>{chunks}</span>}</FormattedMessage></a>
                  )
                }
              </div>
              <div className="d-flex links-contact-call">
                {currentDealer.phone &&
                  (
                    <a href={encodeURI("tel:" + currentDealer.phone)}><span className="icon icon-phone"></span> <FormattedMessage id="offers.single.button_call_dealer" values={{ lb: (...chunks) => <span><br />{chunks}</span> }}>{(...chunks) => <span>{chunks}</span>}</FormattedMessage></a>
                  )
                }
              </div>
            </div>
          </div>


        </div>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Offers" />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="offers" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default OfferSingle
