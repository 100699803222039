import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {useIntl} from "gatsby-plugin-intl"

const Cookies = ({handleClose}) => {
  const intl = useIntl();

  return (
    <>
      <SEO title="COOKIES" />
      <div id="legals">
        <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "cookies.title2" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text2" })}} />

        <div>
          <button className="ot-sdk-show-settings button red mt-4 mb-4 m-0"
                  onClick={(e) =>{e.preventDefault();document.getElementById("axeptio_main_button").click(); handleClose(null) }}>{intl.formatMessage({ id: "cookies.btn_settings" })}
          </button>
        </div>

        <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "cookies.title3" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text3" })}} />

        <div>
          <button className="ot-sdk-show-settings button red mt-4 mb-4 m-0"
                  onClick={(e) =>{e.preventDefault();document.getElementById("axeptio_main_button").click(); handleClose(null) }}>{intl.formatMessage({ id: "cookies.btn_settings" })}
          </button>
        </div>
        { (intl.locale === 'fr-FR' || intl.locale === 'fr-BE' || intl.locale === 'fr-LU') &&
          <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({id: "cookies.title4"})}</h2>
        }
        { (intl.locale === 'fr-FR' || intl.locale === 'fr-BE' || intl.locale === 'fr-LU') &&
          <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "cookies.text4"})}} />
        }

        <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "cookies.title5" })}</h2>
        <h3>{intl.formatMessage({ id: "cookies.subtitle1" })}</h3>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text5" })}} />
        <h3>{intl.formatMessage({ id: "cookies.subtitle2" })}</h3>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text6" })}} />
        <h3>{intl.formatMessage({ id: "cookies.subtitle3" })}</h3>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text7" })}} />

        <h2 className="with-border-top p-0 mt-5">{intl.formatMessage({ id: "cookies.title7" })}</h2>
        <h3>{intl.formatMessage({ id: "cookies.subtitle4" })}</h3>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text9" })}} />

        <div>
          <button className="ot-sdk-show-settings button red mt-4 mb-4"
                  onClick={(e) =>{e.preventDefault();document.getElementById("axeptio_main_button").click(); handleClose(null) }}>{intl.formatMessage({ id: "cookies.btn_settings" })}
          </button>
        </div>
      </div>

    </>
  )
}

export default Cookies

