import React from "react"
import {useIntl} from "gatsby-plugin-intl"

const TropheeNameBlock = ({currentUser, userGender}) => {

  const intl = useIntl();

  return(
    <>
      {/*{(() => {*/}
      {/*  switch (currentUser.status) {*/}
      {/*    case 1:*/}
      {/*      return (<span className="icon-win icon-win-text"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="text">{intl.formatMessage({ id: "generic.status_level." + currentUser.status })}</span></span>);*/}
      {/*    case 2:*/}
      {/*      return (<span className="icon-win icon-win-text silver"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="text">{intl.formatMessage({ id: "generic.status_level." + currentUser.status })}</span></span>);*/}
      {/*    case 3:*/}
      {/*      return (<span className="icon-win icon-win-text gold"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="text">{intl.formatMessage({ id: "generic.status_level." + currentUser.status })}</span></span>);*/}
      {/*    case 4:*/}
      {/*      return (<span className="icon-win icon-win-text platinum"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="text">{intl.formatMessage({ id: "generic.status_level." + currentUser.status })}</span></span>);*/}
      {/*  }*/}
      {/*})()}*/}

      <h3 className="pt-3 mb-2">{userGender} {currentUser.lastName}</h3>
    </>
  )
}

export default TropheeNameBlock


