import React, {useEffect, useState} from "react"

import {ButtonGroup, Button, Form} from "react-bootstrap"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link, navigate, useIntl} from "gatsby-plugin-intl"
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import instance from "../../../components/api/httpclient";

const Step1 = ({nextStep, genericChange, handleChange, state, setState}) => {

  const intl = useIntl();
  const [privacyLink, setPrivacyLink] = useState('/privacy');
  const [invitationLoading, setInvitationLoading] = useState(false);

  useEffect((event) => {
    if ( intl.locale === 'fr-BE' || intl.locale=== 'fr-LU') {
      registerLocale('locale', fr)
    } else {
      registerLocale('locale', nl)
    }

    setState({
      language: intl.locale,
      country: intl.locale.substr(3,2)
    });

    setPrivacyLink('/' + intl.locale + '/privacy');

    let paramSource = '';
    let paramMedium = '';
    let paramCampaign = '';
    let paramInvitation = '';
    if (typeof window !== 'undefined') {

      const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      };

      paramSource = getUrlParameter('source');
      paramMedium = getUrlParameter('medium');
      paramCampaign = getUrlParameter('campaign');
      paramInvitation = getUrlParameter('invitation');
    }

    setState({
      source: paramSource
    });

    setState({
      medium: paramMedium
    });

    setState({
      campaign: paramCampaign
    });

    if (paramInvitation) {
      getInvitation(paramInvitation);
      setState({
        invitation: paramInvitation
      });
    }



  },[]);

  const getInvitation = ((invitationCode) => {
    setInvitationLoading(true);
    instance.get(`/invitation/${invitationCode}`,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then( (res) => {
          setState({
            first_name: res.data.firstName,
            last_name: res.data.lastName,
            email: res.data.email,
            stepEmail: res.data.email,
          })
          setInvitationLoading(false);
        }).catch((error) => {
          setInvitationLoading(false);
          if (error.toString().includes("400")) {
            navigate('/sessions/invitation-canceled');
          }
        });
  });

  const switchBtn = (e) => {
    setState({
      gender: e.target.name
    });
    genericChange({gender: e.target.name});
  };
  const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']
  const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
  const locale = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  }
  const handleChangeForm = (date) => {
    setState({date: date})
    genericChange({birthday: date});
  };

  const checkboxChange = () => {
    let _state = {optin: !state.optin};
    setState(_state);
    genericChange(_state);
  };

  return (
    <div>
      <div className="white-container mb-5">

        <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "1_1_1_1_login_sign_up_step1.title" })}</h1>

        <p className="mb-3">{intl.formatMessage({ id: "1_1_1_1_login_sign_up_step1.description" })}</p>

        <form className="row" onSubmit={(event) => nextStep(event)}>

          <div className="col-md-4">
            <Form.Group>
              <Form.Label className="required text-uppercase">{intl.formatMessage({ id: "generic.forms.form_field_labels.user_title" })}</Form.Label>
              <ButtonGroup aria-label="title">
                <Button className={typeof state !== "undefined" && state.gender === "1" ? "selected" : ""} name="1" onClick={(event) => switchBtn(event)}>{intl.formatMessage({ id: "generic.forms.form_options.user_title.1" })}</Button>
                <Button className={typeof state !== "undefined" && state.gender === "0" ? "selected" : ""} name="0" onClick={(event) => switchBtn(event)}>{intl.formatMessage({ id: "generic.forms.form_options.user_title.0" })}</Button>
                <Button className={typeof state !== "undefined" && state.gender === "2" ? "selected" : ""} name="2" onClick={(event) => switchBtn(event)}>{intl.formatMessage({ id: "generic.forms.form_options.user_title.2" })}</Button>
              </ButtonGroup>
            </Form.Group>
          </div>

          <div className="w-100"></div>

          <div className="col-md-4">
            <Form.Group>
              <Form.Label className="required text-uppercase">{intl.formatMessage({ id: "generic.forms.form_field_labels.first_name" })}</Form.Label>
              <Form.Control type="text" value={state ? state.first_name : ''} name="first_name" onChange={handleChange}/>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group>
              <Form.Label className="required text-uppercase">{intl.formatMessage({ id: "generic.forms.form_field_labels.last_name" })}</Form.Label>
              <Form.Control type="text" value={state ? state.last_name : ''} name="last_name" onChange={handleChange}/>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group controlId="formGroupEmail" className="mb-0">
              <Form.Label className="">{intl.formatMessage({ id: "generic.forms.form_field_labels.date_of_birth" })}</Form.Label>
              <DatePicker className="form-control" dateFormat="dd/MM/yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          placeholderText="JJ/MM/AAAA"
                          dropdownMode="select"
                          selected={state ? state.birthday : null}
                          onChange={(event) => handleChangeForm(event)}
                          locale={locale}
              />
            </Form.Group>

            {/* <Form.Group className="mb-4">
              <div className="position-relative">
                <small className="form-text"><span className="birth-info" onClick={() => setState({infoOpen: !state.infoOpen})}>
                {intl.formatMessage({ id: "generic.forms.form_mentions.why_date_of_birth" })}</span></small>
                {(() => {
                  if(state && state.infoOpen) {
                    return (
                      <div className="info-bulle">
                        {intl.formatMessage({ id: "1_1_1_1_login_sign_up_step1.popup_information" })}
                      </div>
                    )
                  }
                })()}
              </div>
            </Form.Group> */}

          </div>

          <div className="col-md-8 pt-2">

            <Form.Group className="checkbox-custom mt-4">
              <input type="checkbox" id="optin" name="optin" checked={state ? state.optin : ""} className="form-control" onChange={() => checkboxChange()}/>
              <label htmlFor="optin" title="" className="form-label multiline">
                {intl.formatMessage({ id: "1_1_1_1_login_sign_up_step1.optin" })}
                <a href={privacyLink} target="_blank">{intl.formatMessage({ id: "1_1_1_1_login_sign_up_step1.optin-link-text" })}</a>
                <span className="dark-red">*</span>
              </label>
            </Form.Group>
          </div>

          <div className="w-100"></div>


          <div className="col-md-4">
            <div className="required-field mb-4">*{intl.formatMessage({ id: "generic.forms.form_mentions.required_fields" })}</div>
          </div>

          <div className="col-md-4 ml-md-auto">
            <button type="submit" className={"button red mx-auto mr-md-0 " + ((true === invitationLoading || (state && state.nextButtonDisabled)) ? "disabled" : "")}>{intl.formatMessage({ id: "generic.nav_buttons.next_button" })}</button>
          </div>

        </form>
      </div>

      <p className="text-center">{intl.formatMessage({ id: "generic.forms.form_mentions.already_member" })}</p>

      <div className="mb-4 text-center">
        <Link to="/sessions/login" className="button white mx-auto">{intl.formatMessage({ id: "generic.forms.form_buttons.signin" })}</Link>
      </div>

    </div>
  )
}


export default Step1
