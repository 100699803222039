import React, {useEffect, useState} from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import instance from "../../components/api/httpclient";
import RewardSlider from "./reward-slider"

import {FormattedMessage, useIntl} from "react-intl";
import imgVehicle from "../../assets/images/vehicle/JUKE_F15-A1-min.png";

class BodyClass extends React.Component {
  // static propTypes = {
  //   name: React.PropTypes
  // }
  // static defaultProps = {
  //   name: ''
  // }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const RewardList = () => {
  const [rewardsCat1, setRewardsCat1] = useState([]);
  const [rewardsCat2, setRewardsCat2] = useState([]);
  const [rewardsCat3, setRewardsCat3] = useState([]);
  const [currentCars, setCurrentCars] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);

  const intl = useIntl();

  useEffect(() => {
    instance.get("/rewards/locale/"+ intl.locale+"/1",
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then( (response) => {
          setRewardsCat1(response.data.rewards);

          instance.get("/rewards/locale/"+ intl.locale+"/2",
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
              }
            })
            .then( (response) => {
              setRewardsCat2(response.data.rewards);

              instance.get("/rewards/locale/"+ intl.locale+"/3",
                {
                  headers: {
                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                  }
                })
                .then( (response) => {
                  setRewardsCat3(response.data.rewards);
                  pageContent();
                }).catch( (error) => {
                console.log('error', error);
              });

            }).catch( (error) => {
            console.log('error', error);
          });
      }).catch((error) => {
        console.log('error', error);
        if (error.toString().includes("403")) {
          setHasAccess(false);
        }
      });

    setTooltipOpened(!JSON.parse(localStorage.getItem('currentUser')).welcomeRewards);


  },[]);

  const hasCars = () => {

    if (typeof window !== 'undefined') {
      if (JSON.parse(localStorage.getItem('currentCars'))) {
        return JSON.parse(localStorage.getItem('currentCars')).length
      }
    }
  }

  const closeTooltip = (e) => {
    instance.post('/welcome',
      {
        type: 'rewards'
      },
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      }).then(function (response) {
      if (typeof window !== 'undefined' ) {
        localStorage.setItem('currentUser', JSON.stringify(response.data.user));
        setCurrentUser(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {});
      }
    });
    e.preventDefault();
    setTooltipOpened(false);
  };

  const pageContent = () => {
    return(
      <div className="col-lg-8">
         {tooltipOpened &&

          <div className="tooltip-custom dark-red-border mb-4 mt-3 mx-3 mx-sm-0 mt-lg-0">
            <a href="#" className="close dark-red" onClick={(e) => closeTooltip(e)}><span>+</span></a>
            <h3 className="dark-red"><span className="icon-questions align-middle fs-20 mr-2"></span>{intl.formatMessage({ id: "reward_shop.notice_box.welcome" })}</h3>
            <p className="mb-0 dark-red">{intl.formatMessage({ id: "reward_shop.notice_box.description" })}</p>
          </div>
         }

        {hasCars() === 0 &&
        <div className="bg-dark-red p-4 mb-4 rounded">
          <p className="text white mb-4">
            {intl.formatMessage({id: "reward_shop.alert_box.no_associated_vehicle"})}</p>

          <div className="text-center">
            <Link to="/mycars/add-car" className="button dark-red bg-white d-inline-block position-relative">
              <div className="icon-car icon-car-new dark-red mr-2 d-inline-block"></div>
              <span>{intl.formatMessage({id: "reward_shop.alert_box.button"})}</span></Link>
          </div>
        </div>
        }

        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({ id: "reward_shop.title" })}</h1>
          <p className="mb-4">{intl.formatMessage({ id: "reward_shop.description" })}</p>

          <div className="reward-list">
            <div className="text mb-2"><strong>{intl.formatMessage({ id: "reward_shop.items.1.title" })}</strong></div>
            <p className="mb-4">{intl.formatMessage({ id: "reward_shop.items.1.description" })}</p>
            <RewardSlider rewards={rewardsCat1} />
          </div>

          <div className="reward-list">
            <div className="text mb-2"><strong>{intl.formatMessage({ id: "reward_shop.items.2.title" })}</strong></div>
            <p className="mb-4">{intl.formatMessage({ id: "reward_shop.items.2.description" })}</p>
            <RewardSlider rewards={rewardsCat3} />
          </div>

          <div className="reward-list">
            <div className="text mb-2"><strong>{intl.formatMessage({ id: "reward_shop.items.3.title" })}</strong></div>
            <p className="mb-4">{intl.formatMessage({ id: "reward_shop.items.3.description" })}</p>
            <RewardSlider rewards={rewardsCat2} />
          </div>
        </div>
      </div>
    )
  };

  const userCantAccess = () => {
    return (
      <div className="col-lg-8">
        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({ id: "reward_shop.title" })}</h1>
          <p className="mb-4">{intl.formatMessage({ id: "reward_shop.description" })}</p>

          <div className="col-lg-8">
            <p className="mb-4">
              {intl.formatMessage({ id: "reward_shop.message.user_cant_access" })}
            </p>
          </div>
        </div>
      </div>
    );
  };

    return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.rewards.reward-list" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={hasAccess ? pageContent() : userCantAccess()} currentPage="reward" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
    )
}

export default RewardList
