import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import {useIntl} from "react-intl";
import {useMediaQuery} from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};

const HowCan = () => {
  const intl = useIntl();
  const currentDealer = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentDealer')) : {};

  return (
      <Layout>
        <SEO title={intl.formatMessage({ id: "pages_meta_title.contact.how-can-help-you" })} />

        <Link to="/maintenance/timeline" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</Link>

        <div className="white-container mb-5">

          <h2 className="text-uppercase text-center mb-0 py-4 bg-darker-grey white"><span className="icon-questions fs-30 align-middle mr-2"></span>{intl.formatMessage({ id: "contact.title" })}</h2>


          <ul className="list-link-picto light-grey-simple-border">
            <li className="mb-0 py-4 w-100"><Link to="/contact/contact-my-dealer" state={{id : currentDealer ? currentDealer.id : ""}}><span className="icon-contact icon dark-red"></span><h3 className="mb-0">{intl.formatMessage({ id: "contact.contact_dealer" })}</h3></Link></li>
            <li className="mb-0 py-4 w-100"><a href="https://www.nissan.fr/service-clients/contact/poser-une-question.html" target="_blank" className="mx-md-4"><span className="icon-questions icon dark-red"></span><h3 className="mb-0">{intl.formatMessage({ id: "contact.contact_reward" })}</h3></a></li>

          </ul>

        </div>

      </Layout>
    )
}

export default HowCan
