import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"

import instance from "../../components/api/httpclient";
import {navigate, useIntl, FormattedDate, Link} from "gatsby-plugin-intl";
import moment from "moment";

import OfferSlider from "./offer-slider"
import ENV from "../../../env";


class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const OffersList = () => {
  const intl = useIntl();

  const [offers, setOffers] = useState({});


  useEffect(() => {
    instance.get("/offers/locale/"+ intl.locale,
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then( (response) => {
        let formattedOffers = response.data;
        formattedOffers.forEach((offer, key) => {
          let mediaUrl = offer.sliderImage;
          if (null === mediaUrl) {
            mediaUrl = offer.thumbnail;
          }
          instance.get(mediaUrl,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
              }
            })
            .then(function (res) {
                if (undefined !== res.data.contentUrl) {
                  offer.image = process.env.API_URL + res.data.contentUrl;
                }
                offer.image = process.env.API_URL + res.data.contentUrl;
                
                if (key === formattedOffers.length-1) {
                  setOffers(formattedOffers);
                  // pageContent();
                }
              }
            );
        });
        pageContent();
      }).catch( (error) => {
      console.log('error', error);
    });
  },[]);

  const hasCars = () => {

    if (typeof window !== 'undefined') {
      if (JSON.parse(localStorage.getItem('currentCars'))) {
        return JSON.parse(localStorage.getItem('currentCars')).length
      }
    }
  }


  const pageContent = () => {
    return(

      <div className="col-lg-8">
        {hasCars() === 0 &&
        <div className="bg-dark-red p-4 mb-4 rounded">
          <div className="text-center mb-4">
            <span className="icon-car icon-car-new white big-add-car"></span>
          </div>

          <p className="text white mb-4">
            {intl.formatMessage({id: "offers.alert_box.no_associated_vehicle"})}</p>

          <div className="text-center">
            <Link to="/mycars/add-car" className="button dark-red bg-white d-inline-block position-relative">
              <div className="icon-car icon-car-new dark-red mr-2 d-inline-block"></div>
              {intl.formatMessage({id: "offers.alert_box.button"})}</Link>
          </div>
        </div>
        }


        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({id: "offers.title"})}</h1>
          <p className="mb-4"> {intl.formatMessage({id: "offers.description"})}</p>


          <div className="reward-list">
            {/*<div className="text mb-2"><strong>{intl.formatMessage({id: "offers.items.1.title"})}</strong></div>
            <p className="mb-4">{intl.formatMessage({id: "offers.items.1.description"})}</p>*/}

            <OfferSlider offers={offers} />
          </div>

        </div>
      </div>
    )
  };


  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.offers.offers-list" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="offers" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default OffersList
