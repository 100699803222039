import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import instance from "../../components/api/httpclient";
import {FormattedDate, useIntl} from "react-intl";
import {Link} from "gatsby-plugin-intl";

class BodyClass extends React.Component {
  // static propTypes = {
  //   name: React.PropTypes
  // }
  // static defaultProps = {
  //   name: ''
  // }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const RewardHistory = () => {
  const intl = useIntl();
  const [rewardEvents, setRewardEvents] = useState([]);
  useEffect(() => {
        instance.get("rewards-history",
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            console.log(response.data.history);
            setRewardEvents(response.data.history);
          }).catch((error) => {
          console.log('error', error);
        });
  },[]);

  const displayEvent = () => {
    if (rewardEvents && rewardEvents.length > 0) {
      return (
        rewardEvents.map((rewardEvent, index) =>
          (
          <div className="event" key={index} >
            <div className="event-icon">
              <span className={rewardEvent.type === 'reward' ? "icon-reward" : "icon-cup"}></span>
            </div>
            <div className="container-event">
              <header>
                {rewardEvent.type === 'reward' ?
                  (<h3 className="mb-1">{intl.formatMessage({ id: "rewards_history.downloaded" })}</h3> ) :
                  (<h3 className="mb-1">{intl.formatMessage({ id: "rewards_history."+rewardEvent.status+".title" })}</h3>)
                }
                <h4 className="mb-0">
                  <FormattedDate
                    value={rewardEvent.date}
                    day="2-digit"
                    month="long"
                    year="numeric"
                  />
                </h4>
              </header>

              {rewardEvent.type === 'reward' ?
                (<Link to="#" onClick={(event => getVoucher(event, rewardEvent.rewardVoucherId))}><div className="encart">
                  <img src={rewardEvent.rewardImgThumb} alt=""/>
                <h4>{rewardEvent.rewardName}</h4>
                <p><i className="icon-ticket-f"></i> {rewardEvent.rewardOffer}</p>
                </div></Link>)
                :
                (<div className="text">
                  {intl.formatMessage({ id: "rewards_history."+rewardEvent.status+".description" })}
                </div>)
              }

            </div>
          </div>
          )
        )
      );
    }
  };

  const getVoucher = (event, voucherId) => {
    if (voucherId) {
      instance.get("/rewards/voucher/" + voucherId,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          },
          responseType: 'blob'
        })
        .then((response) => {
          const file = new Blob(
            [response.data],
            {type: 'application/pdf'});
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          if (window) {
            window.open(fileURL);
          }

        }).catch((error) => {
        console.log('error', error);
      });
    }

    event.preventDefault();
  };

  const pageContent = () => {
    return(
      <div className="col-lg-8">

        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">
            {intl.formatMessage({ id: "my_profil.rewards_history" })}
          </h1>

          <div className="row">
            <div className="col-md-6">

              <div className="reward-history">

                {displayEvent()}

                {rewardEvents && rewardEvents.length > 0 ?
                  (<div className="event">
                    <div className="event-icon">
                      <span className="icon-profile"></span>
                    </div>
                    <div className="container-event">
                      <header>
                        <h3 className="mb-1">{intl.formatMessage({ id: "rewards_history.0.title" })}</h3>
                        <h4 className="mb-0">
                          <FormattedDate
                            value={rewardEvents[rewardEvents.length - 1].date}
                            day="2-digit"
                            month="long"
                            year="numeric"
                          />
                        </h4>
                      </header>
                    </div>
                  </div>)
                : ""}

              </div>

            </div>
            <div className="col-md-6">
              <Link to="/rewards/about-youplus" className="button white mw-100 pl-3">
                <span>{intl.formatMessage({ id: "generic.nav_buttons.how_to_improve_status" })}</span>
              </Link>
            </div>
          </div>

        </div>
      </div>
    )
  }

    return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.rewards.reward-history" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="reward" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
    )
}

export default RewardHistory
