import React, {useEffect, useState} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Link} from "gatsby-plugin-intl";
import {useIntl} from "react-intl";
import axios from "../../components/api/httpclient";
import {useMediaQuery} from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};

const CantFindAnswer = () => {
  const intl = useIntl();
  const [basisPhone, setBasisPhone] = useState('');

  useEffect(() =>{

      axios.get(`/last-parameters`,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then(function (response) {
          switch (intl.locale) {
            case "fr-LU":
              setBasisPhone(response.data.parameter.basisPhoneFrLu);
              break;

            case "fr-BE":
              setBasisPhone(response.data.parameter.basisPhoneFrBe);
              break;

            case "nl-BE":
              setBasisPhone(response.data.parameter.basisPhoneNlBe);
              break;

            case "nl-NL":
              setBasisPhone(response.data.parameter.basisPhoneNlNl);
              break;

          }
        });

  }, [])


    return (
      <Layout>
        <SEO title={intl.formatMessage({ id: "pages_meta_title.contact.cant-find-answer" })} />
        <Link to="/contact/how-can-help-you" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</Link>

        <div className="white-container mb-5">

          <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "contact.about_voucher.title" })}</h1>


          <div className="row">
            <div className="col-lg-6">
              <a className="button white with-icon with-arrow mx-auto mr-lg-0 mb-4" href={encodeURI("tel:"+basisPhone)} target="_blank"><span className="icon-phone icon"></span><span>{intl.formatMessage({ id: "contact.about_voucher.call_button" })}</span></a>
            </div>
            <div className="col-lg-6">
              <Link to="/contact/contact-form" state={{type: "basis" }} className="button white with-icon with-arrow mx-auto mx-lg-0"><span className="icon-inbox icon fs-20"></span><span>{intl.formatMessage({ id: "contact.about_voucher.form_button" })}</span></Link>
            </div>
          </div>


        </div>

      </Layout>
    )

}

export default CantFindAnswer
