import React from "react"

import {Form} from "react-bootstrap"
import axios from "../../../components/api/httpclient";
import { navigate, Link, FormattedMessage } from "gatsby-plugin-intl"
import moment from "moment";

class Step3 extends React.Component {
  constructor(options) {
    super(options);
    this.state = this.props.state;
  }

  /* Toggle password visibility */
  togglePwd() {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  }

  onSubmit(event) {
    event.preventDefault();
    let that=this;

    // Submit Function
    if(!this.props.formValidation()) {
        if(this.state.birthday){
            this.props.state.birthday.setHours( this.props.state.birthday.getHours() + 2 );
        }

      const response = axios.post(`/users`,
        {
          firstName: this.props.state.first_name,
          lastName: this.props.state.last_name,
          particleName: this.props.state.particle_name,
          gender: parseInt(this.props.state.gender),
          username: this.props.state.username,
          email: this.props.state.email,
          password: this.props.state.password,
          birthDate: this.props.state.birthday ?? null,
          optin: this.props.state.optin,
          status: 1,
          subscriptionDate: moment().format(),
          language: this.props.state.language,
          country: this.props.state.country,
          streetName: this.props.state.street_name,
          houseNumber: (this.props.state.house_number || '').toString(),
          houseNumberExt: this.props.state.house_number_ext,
          postalCode: this.props.state.postal_code,
          city: this.props.state.city,
          reminderChannelNotification: false,
          reminderChannelSMS: false,
          reminderChannelEmail: false,
          offerChannelNotification: false,
          offerChannelSMS: false,
          offerChannelEmail: false,
          welcomeTimeline: false,
          welcomeRewards: false,
          source: this.props.state.source,
          medium: this.props.state.medium,
          campaign: this.props.state.campaign,
          invitation: this.props.state.invitation ?? null
        }).then(function (response) {

          navigate('/sessions/email-validation',
            {
              state: {
                email: that.props.state.email,
                first_name: that.props.state.first_name,
                particle_name: that.props.state.particle_name,
                last_name: that.props.state.last_name
              },
            });
        }
        ).catch( (error) => {
         that.setState({ account_exists: true });

        });
    }
  }

  emailOnChange(e) {
    this.setState({stepEmail: e.target.value.toLowerCase()});
    this.props.handleChange(e);
  }

  render () {
    return (
    <div>

      <div className="white-container mb-5">

        <h1 className="with-border-top with-border-bottom text-uppercase"><FormattedMessage id="1_1_2_login_sign_up_step2_signup.title" /></h1>

        <p className="mb-3"><FormattedMessage id="1_1_2_login_sign_up_step2_signup.description"/></p>

        <form className="row" onSubmit={this.onSubmit.bind(this)}>

          <div className="col-md-6">
            <Form.Group>
              <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.email" /></Form.Label><span className="icon-check ml-2 d-none"></span>
              <Form.Control type="email" name="email" onChange={this.emailOnChange.bind(this)} value={this.state != null ? this.state.stepEmail: ""} />
              {this.props.state && this.props.state.formErrors['email'] === "error" &&
              <small className="form-text form-validate-error"><span>X</span><FormattedMessage id="generic.forms.form_error_message.error_message_1" /></small>
              }
              {this.props.state && this.state.account_exists === true &&
              <small className="form-text form-validate-error"><span>X</span><FormattedMessage id="generic.forms.form_error_message.error_message_9" /></small>
              }

            </Form.Group>
          </div>


          <div className="col-md-6 mb-md-4">
            <Form.Group>
              <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.password" /></Form.Label><span className="icon-check ml-2 d-none"></span>
              <div className="password-wrapper">
                {this.props.state && this.state.isPasswordVisible ?
                  <input
                    className="form-control"
                    type='text'
                    name="password"
                    onChange={this.props.handleChange}
                    value={this.props.state ? this.props.state.password : ""}
                    />

                  :
                  <input
                    className="form-control"
                    type='password'
                    name="password"
                    onChange={this.props.handleChange}
                    value={this.props.state ? this.props.state.password : ""}
                    />}

                <span className={"icon-eye toggle-pwd " + (this.props.state && this.state.isPasswordVisible ? "grey-border" : "")} onClick={this.togglePwd.bind(this)}></span>
              </div>
              {/*<small className="form-text form-validate-ok"><span className="icon-check mr-2"></span>Contains caps letter</small>*/}
              {this.props.state && this.props.state.formErrors['password'] === "error" &&
              <small className="form-text form-validate-error"><span>X</span><FormattedMessage id="generic.forms.form_error_message.error_message_3" /></small>
              }
            </Form.Group>
          </div>

          <div className="col-12 mb-4">
            <p className="grey-border"><FormattedMessage id="1_1_1_login_sign_up_step3_ok.agreement" /></p>
          </div>

          <div className="col-md-4">
            <div className="required-field mb-4">*<FormattedMessage id="generic.forms.form_mentions.required_fields" /></div>
          </div>

          <div className="col-md-4 ml-md-auto">
            <button type="submit" className={"button red mx-auto mr-md-0 " + (this.props.state && this.props.state.submitButtonDisabled ? "disabled" : "")}><FormattedMessage id="generic.forms.form_buttons.signup" /></button>
          </div>

        </form>
      </div>

      <p className="text-center"><FormattedMessage id="generic.forms.form_mentions.already_member" /></p>

      <div className="mb-4 text-center">
        <Link to="/sessions/login" className="button white mx-auto"><FormattedMessage id="generic.forms.form_buttons.signin" /></Link>
      </div>
    </div>
    )
  }
}

export default Step3
