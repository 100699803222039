import React, {useState, useEffect} from "react"

import SEO from "../../components/seo"

import { Form } from "react-bootstrap"
import axios from "../../components/api/httpclient";
import { useIntl, navigate } from "gatsby-plugin-intl";
import Geocoder from 'react-mapbox-gl-geocoder'


const AddDealer = (navigateTo) => {
  const intl = useIntl();
  const [dealers, setDealers] = useState([]);
  const [query, setQuery] = useState('');
  const [viewport, setViewport] = useState({});
  const [currentPosition, setCurrentPosition] = useState([0, 0]);
  const [didSearchOnce, setDidSearchOnce] = useState(false);

  //const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const MAPBOX_TOKEN = 'pk.eyJ1IjoibWluZG96YSIsImEiOiJjazdlazE5eWQwanFuM29udzNhc2k4cXR0In0.6PgTqJbgk_Y5eJku9GYV0Q';
  const mapAccess = {
    mapboxApiAccessToken: MAPBOX_TOKEN,
    limit:5
  };

  const queryParams = {
    country: intl.locale.substr(3,2),
    language: intl.locale.substr(0,2)
  }

  const options = {
    enableHighAccuracy: true,
    timeout: 30000,
    Infinity:Infinity
  }


  useEffect(() => {
    /*const geo = navigator.geolocation;
    if (!geo) {
      console.log("Geolocation is not supported by this browser.");
      return;
    }
    else {
      navigator.geolocation.getCurrentPosition(showPosition, onError, options);
    }*/

    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', keyDownHandler);

    navigator.permissions &&
    navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
      if('denied' === PermissionStatus.state) {
        document.getElementById('current-location').style.display = 'none';
      }
    })


    if (currentPosition[0] !== 0 && currentPosition[1] !== 0 && query.length>0) {
      searchDealers(query);
    }

  }, [currentPosition]);

  const withMyCurrentLocation = (event) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, onError, options);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }

    document.getElementById("input").value = "";
    searchDealers(query);
    event.preventDefault();
  };

  const showPosition = (position) => {
    setCurrentPosition([position.coords.longitude, position.coords.latitude]);
  };

  const onError = (err) => {
    console.log(`ERROR (${err.code}): ${err.message}`);
  };

  const searchDealers = (search) => {

    let country = '';

    switch(intl.locale) {
      case 'fr-BE':
        country = 'BE';
        break;
      case 'fr-FR':
        country = 'FR';
        break;
      case 'fr-LU':
        country = 'LU';
        break;
      case 'nl-NL':
        country = 'NL';
        break;
      case 'nl-BE':
        country = 'BE';
        break;
    }

    let url = `/dealers/search/` + 'country=' + country + '&search=' + search;
    if ([0, 0] !== currentPosition) {
      url = url + '&coordinates=' + currentPosition[0] + ',' + currentPosition[1];
    }

    axios.get(url,
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then(function (response) {
        let formattedDealers = [];

        response.data.dealers.forEach(dealer => {
          formattedDealers.push({
            dealer: dealer.dealer,
            distance: dealer.distance
          })
        });

        setDealers(formattedDealers);
        setDidSearchOnce(true);

      })
  };

  const chooseDealer = (e, id) => {
    e.preventDefault();

    axios.post(`/add-dealer`,
      {
        dealerId: id
      },
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then(function (response) {
        if (typeof window !== 'undefined') {
          localStorage.setItem('currentDealer', JSON.stringify({
            id: response.data.dealer.id,
            name: response.data.dealer.name
          }));
        }
        navigate(navigateTo.navigateTo, {
          state: {
            id: response.data.dealer.id
          }
        });
      });
  };

  const handleSelected = (viewport, item) => {
    setQuery(item.text);
    setCurrentPosition([viewport.longitude, viewport.latitude]);
    setViewport(viewport);
  };



  const placeHolder = (props) => <input {...props} id="input" placeholder={intl.formatMessage({id: "generic.forms.form_mentions.location"})} />

  const geocoder = () => {
    if (undefined !== MAPBOX_TOKEN) {
      return (
        <Geocoder className="geocoder"
                  {...mapAccess} onSelected={handleSelected} viewport={viewport} hideOnSelect={true}
                  inputComponent={placeHolder} queryParams={queryParams} updateInputOnSelect={true}

        />
      )
    }
  };

  const cards = Object
    .keys(dealers)
    .map(key => <li key={dealers[key].dealer.id}>
      <a href="" onClick={(e) => chooseDealer(e, dealers[key].dealer.id)} style={{textDecorationLine:'none'}}>
        <span className="icon-dealer icon"></span>
        <span className="distance">{dealers[key].distance} KM</span>
        <h3>{dealers[key].dealer.name}</h3>
        <p>{dealers[key].dealer.address}<br/>{dealers[key].dealer.city}<br/>{dealers[key].dealer.postcode}</p>
      </a>
    </li>);

  return (
    <div>
      <SEO title="Choose your dealer"/>


      <div className="white-container mb-5">

        <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "1_2_2_onboarding_set_dealer.title"})}</h1>

        <div className="row mb-4">

          <div className="col-md-8">
            <Form>
              <div id="geocoder" className="form-control">
                {geocoder()}
              </div>
              {(function() {
                if (didSearchOnce && dealers.length == 0 ) {
                  return  (<small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({ id: "generic.forms.form_error_message.error_message_13" })}</small>)
                }
              })()}
            </Form>
          </div>
          <div className="col-md-4 pt-2">
            <div id="current-location">
               <span className="icon-marker fs-30 mr-2 align-middle"></span> <a href="#" onClick={(event) => withMyCurrentLocation(event)} className="link-nav dark-red"><strong>{intl.formatMessage({ id: "generic.forms.form_mentions.use_my_position" })}</strong></a>
            </div>
          </div>

        </div>

        <hr className="mb-4"/>

        <ul className="list-adresses">
          {cards}
        </ul>

      </div>
    </div>
  )
}


export default AddDealer
