import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Form } from "react-bootstrap"
import MediaLayout from "../../components/media-layout"
import { FormattedMessage, Link, navigate, useIntl } from "gatsby-plugin-intl";
import instance from "../../components/api/httpclient";
import moment from "moment";
// import logoSender from "../../assets/images/logo.png";
// import moment from "moment/moment";

class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const TransferECarnetPage = ({location}) => {
  const intl = useIntl();
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');

  if (!location.state || !location.state.id) {
    navigate('/maintenance/timeline');
  }

  const handleClick = (event) => {
    event.preventDefault();
    if (buyerEmail !== "") {
      instance.post("transfert/to",
        {
          carId: location.state.id,
          email: buyerEmail,
          firstName: firstName,
          lastName: lastName,
          lang: intl.locale
        },
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
      .then((response) => {
        if (response.status === 200) {
          navigate('/mycars/invitation-sent')
        } else if (response.status === 401) {
          navigate('/sessions/logout');
        }
      }).catch((error) => {
        console.log('error', error);
      });
    }
  }

  const pageContent = () => {
    return(
      <div className="col-lg-8">

        <a href="#" onClick={(e) => { e.preventDefault(); window.history.back(); }} className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</strong></a>
        {/*<Link to={"/maintenance/timeline"} className="black mb-4 back-link"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</strong></Link>*/}

        <div className="white-container d-flex flex-column">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">TRANSMETTRE MON CARNET</h1>

          <div className="text-center">

            <p className="">Veuillez saisir les coordonnées du nouveau propriétaire</p>

            <p className=""><strong>Un email lui sera envoyé, il devra alors compléter le transfert du véhicule vers son compte Nissan le Club.</strong></p>

            <Form className="row justify-content-center py-4" onSubmit={(event) => handleClick(event)}>

              <div className="col-md-5 text-left">

                <Form.Group>
                  <Form.Label><FormattedMessage id="generic.forms.form_field_labels.first_name" /></Form.Label>
                  <Form.Control type="text" name="first_name" value={firstName} onChange={
                    (e) => {
                      setFirstName(e.target.value);
                    }} />
                </Form.Group>

              </div>

              <div className="w-100"></div>

              <div className="col-md-5 text-left">
                <Form.Group>
                  <Form.Label><FormattedMessage id="generic.forms.form_field_labels.last_name" /></Form.Label>
                  <Form.Control type="text" name="last_name" value={lastName} onChange={
                    (e) => {
                      setLastName(e.target.value);
                    }} />
                </Form.Group>
              </div>

              <div className="w-100"></div>

              <div className="col-md-5 text-left">

                <Form.Group>
                  <Form.Label className="required">{intl.formatMessage({ id: "contact.form_reward.email" })}</Form.Label>
                  <Form.Control type="email" className="" value={buyerEmail} onChange={
                    (e) => {
                      setBuyerEmail(e.target.value);
                    }} />
                  <small className="form-text form-validate-error d-none"><span>X</span>Please enter a valid email</small>
                </Form.Group>

              </div>

              <div className="w-100"></div>

              <div className="col-md-5">
                <div className="required-field mb-4">*{intl.formatMessage({ id: "contact.form_reward.mandatory_fields" })}</div>
              </div>

              <div className="col-12 text-center">
                <button type="submit" className="button red mx-auto">{intl.formatMessage({ id: "timeline.buttons.button_transfer_final" })}</button>
              </div>
            </Form>
          </div>
        </div>

      </div>
    )
  };

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.mycars.add-car" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="my-cars" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
};

export default TransferECarnetPage
