import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import logoSender from "../../assets/images/logo.png";
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"

import instance from "../../components/api/httpclient";
import {useIntl, Link, navigate} from "gatsby-plugin-intl";
import moment from "moment";
import fr from "moment/locale/fr"
import nl from "moment/locale/nl"

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const Message = ({location}) => {
  const [message, setMessage] = useState({});
  const intl = useIntl();

  useEffect(() => {
    if ( intl.locale === 'fr-BE' || intl.locale=== 'fr-LU') {
      moment.locale('fr');
    } else {
      moment.locale('nl');
    }
    if (location) {
      if (location.state) {
        instance.get("/inbox/" + location.state.id,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            setMessage(response.data.inbox);
            // Mark it as read
            instance.patch("/inbox/" + location.state.id, null,
              {
                headers: {
                  'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                }
              })
              .then((res) => {
                console.log(res)
              }).catch((err) => {
              console.log('error', err);
            });
          }).catch((error) => {
          console.log('error', error);
        });
      }
    }
  }, []);

  const deleteMessage = (event) => {
    if (location) {
      if (location.state) {
        instance.patch("/inbox-delete/" + location.state.id, null,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            navigate('/inbox/inbox-list');
          }).catch((error) => {
          console.log('error', error);
        });
      }
    }
  };


  const content = () => {
    return(
      <div className="col-lg-8">

        <Link to="/inbox/inbox-list" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</Link>

        <div className="white-container d-flex flex-column p-0">

            <div className="inbox-list d-flex align-items-center px-4">
                <div className="inbox-logo">
                    <img src={logoSender} alt="Logo Sender" className="img-fluid" />
                </div>
                <div className="inbox-preview">
                    <h3 className="d-inline-block mr-3">Nissan le club</h3>
                    <p className="d-inline-block mb-0">
                      {moment.utc(message.creationDate).format("DD/MM/YYYY")}
                    </p>
                    <p className="mb-0">{message.subject}</p>
                </div>
                <div className="inbox-date">
                    <a className="button white with-icon" onClick={(event) => deleteMessage(event)}><span className="icon-2-delete icon"></span><span className="d-none d-sm-inline">Supprimer le message</span></a>
                </div>
            </div>
            <iframe srcDoc={message.html} className="inbox-container-msg" width="100%" height="450" frameborder="0">
            </iframe>

        </div>
      </div>
    )
  };

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.inbox.inbox" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={content()} currentPage="inbox" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
};

export default Message
