import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faCalculator } from '@fortawesome/free-solid-svg-icons'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import {Link, navigate, useIntl} from "gatsby-plugin-intl";
import instance from "../../components/api/httpclient";
// import logoSender from "../../assets/images/logo.png";
// import moment from "moment/moment";

class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const CancelCarSellingPage = ({location}) => {
  const intl = useIntl();
  if (!location.state || !location.state.id) {
    navigate('/maintenance/timeline');
  }


  const cancelInvitation = (event, carId) => {
    event.preventDefault();
    if (!carId) {
      return;
    }
    const $alert = event.target.closest('.alert');
    instance.post("transfert/cancel",
      {
        carId: carId,
      },
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then((response) => {
        localStorage.removeItem('currentCars');
        navigate('/maintenance/timeline');
      }).catch((error) => {
        console.log('error', error);
    });
  }

  const pageContent = () => {
    return(
      <div className="col-lg-8">

        <a href="#" onClick={(e) => { e.preventDefault(); window.history.back(); }} className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</strong></a>

        <div className="white-container d-flex flex-column">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">Transmettre mon carnet</h1>

          <div className="text-center">
            <p>Vous souhaitez annuler le transfert en cours du carnet de bord du véhicule ?</p>

            <button
                type="button"
                className="button red"
                onClick={(e) => {
                  if (!location.state) {
                    return;
                  }
                  cancelInvitation(e, location.state.id)
                }}
            >
              {intl.formatMessage({ id: "car.transfert.cancel_invitation" })}
            </button>
          </div>
        </div>

      </div>
    )
  };

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.mycars.add-car" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="my-cars" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
};

export default CancelCarSellingPage
