import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"

import instance from "../../components/api/httpclient";
import {navigate, useIntl, FormattedDate, Link} from "gatsby-plugin-intl";
import moment from "moment";
import GenericVehicle from  "../../assets/images/logo.png"
import ReactImageFallback from "react-image-fallback";

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const Timeline = () => {
  const intl = useIntl();

  const [car, setCar] = useState('');
  const [model, setModel] = useState('');
  const [reminders, setReminders] = useState([]);
  const [lastUpdateDate, setLastUpdateDate] = useState('2020-02-20T08:56:20+00:00');
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentCars, setCurrentCars] = useState([]);
  const [havingCurrentContracts, setHavingCurrentContracts] = useState(false);
  const [currentDealer, setCurrentDealer] = useState([]);
  const [selectedCar, setSelectedCar] = useState('');
  const [selectedWhen, setSelectedWhen] = useState(
    {
    value: 'past',
    name: intl.formatMessage({ id: "timeline.past_events" })
  });
  const [times, setTimes] = useState([
    {
      value: 'next',
      name: intl.formatMessage({ id: "timeline.next_events" })
    },
    {
      value: 'past',
      name: intl.formatMessage({ id: "timeline.past_events" })
    }
  ]);
  const [tooltipOpened, setTooltipOpened] = useState(false);

  useEffect(() => {
    // LOADING USER DATA
    instance.get("/me",
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then( (res) => {

          if (res.status === 200) {
            if (typeof window !== 'undefined' ) {
              localStorage.setItem('currentUser', JSON.stringify(res.data.user));
              //console.log('response user', res)

              if (res.data.user.waitingCarTransfert) {
                navigate('/sessions/waiting-invitation');
              }
              setTooltipOpened(!res.data.user.welcomeTimeline);

              if (0 === res.data.user.cars.length) {
                navigate('/maintenance/timeline-add-car');
              }

              const nbEvents = res.data.user.events.length;
              var events = Array();
              var eventReminders = Array();
              localStorage.setItem('currentEvents', JSON.stringify(events));
              for (let i = 0; i < nbEvents; i++) {
                instance.get(res.data.user.events[i],
                  {
                    headers: {
                      'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                    }
                  })
                  .then( (e) => {
                    if (e.status === 200) {
                      if (typeof window !== 'undefined') {
                        //console.log('response event', e);
                        events.push(e.data);

                        if (!e.data.done && !e.data.reminderSeen && moment(new Date()).diff(e.data.reminderDate, 'days') >= 0) {
                          eventReminders.push(e.data);
                        }

                        setReminders(eventReminders);
                        localStorage.setItem('currentEvents', JSON.stringify(events));
                      }
                    }
                  }).catch( (error) => {
                  //console.log('error', error)
                });
              }

              // Dealer
              instance.get(res.data.user.dealer,
                {
                  headers: {
                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                  }
                })
                .then( (r) => {
                  if (r.status === 200) {
                    if (typeof window !== 'undefined') {
                      localStorage.setItem('currentDealer', JSON.stringify({
                        id: r.data.id,
                        name: r.data.name,
                        address: r.data.address,
                        postcode: r.data.postcode,
                        city: r.data.city,
                        phone: r.data.phone,
                        bookingUrl: r.data.bookingUrl
                      }));

                      setCurrentDealer(r.data);

                      const nbCars = res.data.user.cars.length;
                      var cars = Array();

                      localStorage.setItem('currentCars', JSON.stringify(cars));

                      // Cars
                      for (var i = 0; i < nbCars; i++) {

                        instance.get(res.data.user.cars[i],
                          {
                            headers: {
                              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                            }
                          })
                          .then( (c) => {
                            if (c.status === 200) {
                              if (typeof window !== 'undefined') {
                                //console.log('response car', c)
                                var couple = [];

                                instance.get(c.data.carModel,
                                  {
                                    headers: {
                                      'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                                    }
                                  })
                                  .then( (m) => {
                                    if (m.status === 200) {
                                      if (typeof window !== 'undefined') {
                                        couple.push(c.data);
                                        couple.push(m.data);

                                        cars.push(couple);

                                        localStorage.setItem('currentCars', JSON.stringify(cars));

                                        if (cars.length > 0) {

                                          setCurrentCars(cars.map((car, index) => {
                                            let carData = {
                                              id: car[0].id,
                                              name: car[1].name,
                                              image: car[1].image,
                                              isLeasePlan: car[0].isLeasePlan,
                                              licencePlate: car[0].licencePlate,
                                              firstRegistrationDate: car[0].firstRegistrationDate,
                                              lastMaintenanceDate: car[0].lastMaintenanceDate,
                                              updatedDate: car[0].updatedDate,
                                              lastAutoUpdateDate: car[0].lastAutoUpdateDate,
                                              carTransfert: car[0].carTransfert
                                            }
                                            setSelectedCar(carData);
                                            localStorage.setItem('car', carData);

                                            if (cars.length - 1 === index && cars[nbCars-1][0].id === car[0].id ) {
                                              getMyTimeline(car[0].id, selectedWhen.value);
                                            }

                                            return {
                                              id: car[0].id,
                                              name: car[1].name,
                                              image: car[1].image,
                                              isLeasePlan: car[0].isLeasePlan,
                                              licencePlate: car[0].licencePlate,
                                              firstRegistrationDate: car[0].firstRegistrationDate,
                                              lastMaintenanceDate: car[0].lastMaintenanceDate,
                                              updatedDate: car[0].updatedDate,
                                              lastAutoUpdateDate: car[0].lastAutoUpdateDate,
                                              carTransfert: car[0].carTransfert
                                            };

                                          }));
                                        }
                                      }
                                    }
                                  }).catch( (error) => {
                                  //console.log('error', error)
                                });


                              }
                            }

                          }).catch( (error) => {
                          //console.log('error', error)
                        });
                      }
                    }
                  }

                }).catch( (error) => {
                //console.log('error', error)
              });
            }
          }
          else if (res.status === 401) {
            navigate('/sessions/logout');
          }
        }
      ).catch( (error) => {
        //console.log('error', error);
        navigate('/sessions/logout');
      }
    )
  }, []);

  const getMyTimeline = (carId, when) => {
    if (carId !== undefined && selectedWhen.value === when) {
      try{
        let storageSelectedCar = JSON.parse(localStorage.getItem('car')).car;
          if(storageSelectedCar){
            carId = storageSelectedCar.id;
          }
      } catch (e) {
        console.log('no menu car selected')
      }

    instance.get('/timeline/car/' + carId,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            setAllEvents(response.data.events);
            setEvents(response.data.events['past']);
              if(response.data.carId === carId){
                  if (response.data.hasNext === true ) {
                      setHavingCurrentContracts(true);
                  }else{
                      setHavingCurrentContracts(false);
                      setSelectedWhen({
                        value: 'past',
                        name: intl.formatMessage({ id: "timeline.past_events" })
                      });
                  }
              }
            if (response.data.events['past'].length === 0) {
              setLastUpdateDate(selectedCar.firstRegistrationDate);
            } else {
              setLastUpdateDate(selectedCar.lastMaintenanceDate)
            }
          })
          .catch((error) => {
            //console.log('error', error);
          })
    } else {
      setEvents(allEvents[when]);
    }
  };

  const getIconCss = (eventType) => {

    switch(eventType) {
      case 1:
        return 'icon-calendar-maintenance';
      case 2:
        return 'icon-controle-technique';
      case 3:
        return 'icon-tire';
      case 4:
        return 'icon-tire';
      case 5:
        return 'icon-extension-garanti';
      case 6:
        return 'icon-contracts';
      case 7:
        return 'icon-assistance';
      case 8:
        return 'icon-card';
      case 9:
        return '';
    }
  }

  const getFooter = () => {
    if (true !== selectedCar.isLeasePlan) {
      return (
        <footer>

        </footer>
      )
    }
  }

  const getEvents = () => {
    if (events && events.length > 0) {
      return (
          <div className="timeline-container">
            {events.map((eves, index) => (
                <div className="event" key={eves.date}>
                  <div className="event-icon"></div>
                  <div className="container-event">
                    <header>
                      <h3 className="mb-0">
                        <FormattedDate
                            value={eves.date}
                            day="2-digit"
                            month="long"
                            year="numeric"
                        />
                      </h3>
                      {eves.data.map(eve => (
                          <div>
                            {/*<h3 className="mb-1">{intl.formatMessage({ id: "timeline.event."+eve.type+".title" })}</h3>*/}
                            <div>
                              {/*<span className={getIconCss(eve.type)}></span>*/}

                              <h4 className="mb-1">{ eves.is_contract === true ? "Fin de contrat " +  eve.type : eve.type }

                                {eve.done &&
                                    (
                                        <span className="icon-check alert-icon"></span>
                                    )
                                }
                                {typeof eve.done !== 'undefined' && !eve.done && (moment(new Date()).diff(eve.dueDate, 'days') >= 0) &&
                                    (
                                        <span className="icon-time alert-icon"></span>
                                    )
                                }
                              </h4>
                            </div>
                            {!eve.done && (moment(new Date()).diff(eve.dueDate, 'months') >= 1 && moment(new Date()).diff(eve.dueDate, 'months') < 2) &&
                            (
                            <div className="alert-message"><span className="icon">!</span> 1 {intl.formatMessage({ id: "timeline.mentions.month_late" })}</div>
                            )
                            }
                            {!eve.done && (moment(new Date()).diff(eve.dueDate, 'months') >= 2 && moment(new Date()).diff(eve.dueDate, 'months') <= 3) &&
                                (
                                    <div className="alert-message"><span className="icon">!</span> 2 {intl.formatMessage({ id: "timeline.mentions.months_late" })}</div>
                                )
                            }

                          </div>
                      ))}
                    </header>
                    {eves.data.map(eve => (
                        <div className="text">
                            {/*{intl.formatMessage({ id: "timeline.event."+eve.type+".description" })}*/}
                        </div>
                    ))}
                    {getFooter()}
                  </div>
                </div>
            ))}
          </div>
      );
    } else if (selectedCar.lastAutoUpdateDate){
      return (
          <div className="alert alert-success">
            <span>Pas d'information connue pour ce véhicule.</span>
          </div>
      );
    } else {
      return (
          <div className="alert alert-success">
            <span>Merci pour l’ajout de votre véhicule ! Nous recherchons actuellement l’historique que nous avons sur l’entretien de votre véhicule. Dans 24H, s’il y a des informations connues, elles seront visibles ici. A très vite !</span>
          </div>
      );
    }
  };

  const getCars = () => {
    if (currentCars.length > 0 && selectedCar !== undefined) {
      let storageSelectedCar =null;
      let storageSelectedCarModel =null;
      try{
        storageSelectedCar = JSON.parse(localStorage.getItem('car')).car;
        storageSelectedCarModel = JSON.parse(localStorage.getItem('car')).model;
      } catch (e) {
        localStorage.removeItem('car')
        console.log('no menu car selected')
      }

      return (

        <div className="dropdown">
              <a className="dropdown-toggle text darker-grey" href="#" role="button" id="dropdownMenuLink"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {/*<img src={selectedCar.image} alt={selectedCar.name} className="img-fluid align-middle" width="50"/>*/}
                <ReactImageFallback
                    src={storageSelectedCar ? storageSelectedCarModel.image : selectedCar.image}
                    fallbackImage={GenericVehicle}
                    alt={storageSelectedCar ? storageSelectedCar.name : selectedCar.name}
                    className="img-fluid align-middle"
                    width="50" />
                <span className="d-none d-md-inline ml-3"><strong>{storageSelectedCar ? storageSelectedCarModel.name : selectedCar.name}</strong> {storageSelectedCar ? storageSelectedCar.licencePlate : selectedCar.licencePlate}</span>
              </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {currentCars.map(car => (
              <a className="dropdown-item mb-2 mb-lg-1" href="" key={car.id} onClick={e => selectCar(car, e)}><strong>{car.name}</strong> {car.licencePlate}</a>
            ))}
          </div>
        </div>
      );
    }
  };

  const getTimes = () => {
      if(havingCurrentContracts === true){
          return (
              <div className="dropdown">
                  <a className="dropdown-toggle text darker-grey" href="" role="button" key={selectedWhen.value} id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <strong>{selectedWhen.name}</strong>
                  </a>

                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink2">
                      {times.map(time => (
                          <a className="dropdown-item mb-2 mb-lg-1" href="" key={time.value} onClick={e => selectWhen(time, e)}>{time.name}</a>
                      ))}
                  </div>
              </div>
          );
      }

  };

  const getReminder = () => {
    if (reminders.length > 0) {
      return (
        <div>
          {reminders.map(reminder => (

            <div className="tooltip-custom-event dark-red-border mb-4 mt-3 mx-3 mx-sm-0 mt-lg-0" key={reminder.id}>

              <a href="#" className="close" onClick={(e) => closeReminder(e, reminder.id)}><span>+</span></a>

              <h3 className="white"><span className="icon-questions align-middle fs-20 mr-2"></span> {intl.formatMessage({ id: "timeline.reminder.title"})} :</h3>

              <p className="white mb-0">
                {intl.formatMessage({ id: "timeline.reminder."+reminder.type+".message" })}&nbsp;
                <FormattedDate value={reminder.dueDate} day="2-digit" month="long" year="numeric"/>.
              </p>

            </div>
            ))}
        </div>
      )
    }
  };

  const selectCar = (car, e) => {
    localStorage.setItem('car', car);
    setSelectedCar(car);
    e.preventDefault();
    setHavingCurrentContracts(false);
    setSelectedWhen({
      value: 'past',
      name: intl.formatMessage({ id: "timeline.past_events" })
    });
    getMyTimeline(car.id, selectedWhen.value);
    content();
  };

  const selectWhen = (when, e) => {
    setSelectedWhen(when);
    e.preventDefault();

    getMyTimeline(selectedCar.id, when.value);
    content();
  };

  const closeTooltip = () => {

    instance.post('/welcome',
      {
        type: 'timeline'
      },
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      }).then(function (response) {
        if (typeof window !== 'undefined' ) {
          localStorage.setItem('currentUser', JSON.stringify(response.data.user));
        }
    });

    setTooltipOpened(false);
  };

  const closeReminder = (e, eventId) => {
    console.log(eventId);

    if (eventId !== undefined) {
      instance.get('/timeline/event/seen/' + eventId,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then((response) => {

        })
        .catch((error) => {
          console.log('error', error);
        })
    }
    e.preventDefault();
    e.target.parentNode.parentNode.remove();
  }

  const needToUpdateCar = () => {
    let now = moment(new Date());
    let lastUpdatedDate = moment(selectedCar.updatedDate);

    if (now.diff(lastUpdatedDate, 'months') > 6) {
      return (
      <div className="message-important mb-4">


        <p>{intl.formatMessage({id:"timeline.notice_box.1.description"})}</p>

        <div className="text-center">
          <Link to="/mycars/my-cars/" state={{id: selectedCar.id}} className="button mx-auto with-icon"><span className="icon-pencil icon"></span>{intl.formatMessage({id:"timeline.notice_box.1.button"})}</Link>
        </div>

      </div>
      )
    } else {
      return '';
    }
  };

  const pdfDownloadLink = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}timeline/pdf/${selectedCar.id}`;
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <span className="icon icon-download"></span>
        {intl.formatMessage({ id: "timeline.buttons.button_dld_ecarnet" })}
      </a>
    );
  }

  const content = () => {
    return(
      <div className="col-lg-8">
        {tooltipOpened &&

          <div className="tooltip-custom dark-red-border mb-4 mt-3 mx-3 mx-sm-0 mt-lg-0">

            <div className="close" onClick={() => closeTooltip()}><span>+</span></div>

            <h3 className="dark-red"><span className="icon-questions align-middle fs-20 mr-2"></span>{intl.formatMessage({id:"help_bubulle.welcome.title"})}</h3>

            <p className="dark-red mb-0">{intl.formatMessage({id:"help_bubulle.welcome.description"})}</p>

          </div>
        }

        {/*{getReminder()}*/}

        <div className="white-container">

          {/*<p className="float-right"><em>{intl.formatMessage({id:"timeline.last_update"})}&nbsp;
            <FormattedDate
            value={lastUpdateDate}
            day="2-digit"
            month="long"
            year="numeric"
          /></em></p>*/}

          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({id:"timeline.title"})}</h1>
          <p>{intl.formatMessage({id:"timeline.description"})}</p>

          <div className="d-flex align-items-center justify-content-between">
          {getCars()}
          {getTimes()}
          </div>
          <div className="d-lg-flex justify-content-between my-3">

            <div className="d-flex flex-grow-1">
              {
                (
                  <a href={currentDealer.bookingUrl ?? '/fr-FR/profile/my-dealer'} target={currentDealer.bookingUrl ? '_blank' : '_self'} className="button red full-width w-100 with-icon"><span className="icon icon-calendar"></span>{intl.formatMessage({ id: "timeline.buttons.button_book_dealer" })} <span></span></a>
                )
              }
            </div>
            {/*<hr className="d-lg-none" />*/}
            {/*<div className="d-flex links-contact-call">*/}
            {/*  {currentDealer.phone &&*/}
            {/*    (*/}
            {/*      <a href={encodeURI("tel:" + currentDealer.phone)} target="_blank"><span className="icon icon-phone"></span> <span>{intl.formatMessage({ id: "timeline.buttons.button_call_dealer" })}</span></a>*/}
            {/*    )*/}
            {/*  }*/}
            {/*</div>*/}
          </div>
          {selectedCar.carTransfert &&
              <div className="alert alert-dark">
                <div>
                  <div>{intl.formatMessage({ id: "car.transfert.invitation_already_sent.text" })}</div>
                  <Link
                      to={`/mycars/cancel-car-selling`}
                      state={{id: selectedCar.id}}
                  >
                      {intl.formatMessage({ id: "car.transfert.invitation_already_sent.text_2" })}
                  </Link>
                </div>
              </div>
          }
          <div className="d-sm-flex my-3">

            <div className="d-flex flex-grow-1 links-contact-go">
              {/* <Link to="{{process.env.REACT_APP_API_ENDPOINT}} + `/timeline/pdf/` +  }"><span className="icon icon-download"></span>{intl.formatMessage({ id: "timeline.buttons.button_dld_ecarnet" })}</Link> */}
              {pdfDownloadLink(selectedCar)}
            </div>
            <div className="d-flex flex-grow-1 links-contact-go">
              {console.log(selectedCar)}
                <Link to={`/mycars/sell-car`} className={selectedCar.carTransfert ? 'disabled' : ''} state={{id: selectedCar.id}}><span className="icon icon-recall"></span>{intl.formatMessage({ id: "timeline.buttons.button_sell_vehicle" })}</Link>
            </div>
          </div>

          <hr className="mt-2" />

          {needToUpdateCar()}
          {getEvents()}

        </div>
      </div>
    )
  };

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.maintenance.timeline" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={content()} currentPage="timeline" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
};

export default Timeline
