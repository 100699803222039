import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {useIntl, FormattedMessage, navigate} from "gatsby-plugin-intl"
import instance from "../../components/api/httpclient";

const WaitingInvitation = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [seller, setSeller] = useState(null);
  const [car, setCar] = useState(null);
  const [carModel, setCarModel] = useState(null);
  const [invitationId, setInvitationId] = useState(null);

  const getWaitingInvitation = (() => {
    instance.get("/pending-invitation",
    {
      headers: {
        'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
      }
    })
    .then( (res) => {
      setSeller(res.data.seller)
      setCar(res.data.car)
      setCarModel(res.data.carModel)
      setInvitationId(res.data.id)
      setLoading(false);
    }).catch((error) => {
      navigate('/profile/set-dealer-find');
    });
  });

  useEffect(() => {
    getWaitingInvitation();
  }, []);

  const acceptInvitation = (event) => {
    event.preventDefault();
    if (!invitationId) {
      return;
    }
    instance.post("transfert/accept",
        {
          id: invitationId,
        },
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then((response) => {
            localStorage.removeItem('currentCars');
            window.location.reload();
        }).catch((error) => {
      console.log('error', error);
    });
  }

  const refuseInvitation = (event) => {
    event.preventDefault();
    if (!invitationId) {
      return;
    }
    instance.post("transfert/refuse",
        {
          id: invitationId,
        },
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then((response) => {
            navigate('/sessions/onboarding-start');
        }).catch((error) => {
      console.log('error', error);
    });
  }

  return (
    <Layout>

      <SEO title={intl.formatMessage({ id: "pages_meta_title.sessions.onboarding-start" })} />

      <div className="white-container mb-5">
        {false === loading &&
          <>
            <h1 className="text-center">
              Ajout de mon véhicule
            </h1>

            <p className="text-center mb-4">Souhaitez-vous ajouter le Carnet de bord de ce véhicule dans votre espace Nissan Le Club :</p>
            <p className="text-center mb-4">
              {carModel &&
                  <span><strong>Modèle:</strong> {carModel}<br/></span>
              }
              <strong>VIN:</strong> {car.vin}
            </p>


            <div className="d-flex justify-content-center">
              <button
                  type="button"
                  className="button red mr-1"
                  onClick={(e) => {acceptInvitation(e)}}
              >
                {intl.formatMessage({ id: "car.transfert.accept_invitation" })}
              </button>
              <button
                  type="button"
                  className="button orange"
                  onClick={(e) => {refuseInvitation(e)}}
              >
                {intl.formatMessage({ id: "car.transfert.refuse_invitation" })}
              </button>
            </div>
          </>
        }

      </div>

    </Layout>
  )
}

export default WaitingInvitation

