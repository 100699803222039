import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"

import TropheeNameBlock from "./media-components/trophee-name-block"

import logoYouPlus from "../assets/images/logo-nlc-w.svg"
import useGlobalState from "../context/GlobalContextProvider";
import {useIntl, Link} from "gatsby-plugin-intl"
//import GenericVehicle from "../../public/images/vehicle/generic.png";
import PictoUser from "../assets/images/car.svg";
import ReactImageFallback from "react-image-fallback";
import BlackGenericVehicle from "../assets/images/logo.png";


const MaintenanceLayout = ({ children, currentPage = null }) => {
  const globalState = useGlobalState();
  const intl = useIntl();

  const [currentUser, setCurrentUser] = useState('');
  const [currentDealer, setCurrentDealer] = useState('');
  const [currentCars, setCurrentCars] = useState('');
  const [isLeasePlan, setIsLeasePlan] = useState(false);


  const [mobileToggleMenu, setMobileToggleMenu] = useState(false);

  if (!currentUser) {
    setCurrentUser(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {});
  }

  if (!currentDealer) {
    setCurrentDealer(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentDealer')) : {});
  }

  //if (!currentCars) {
  if (!currentDealer) {
    setCurrentCars(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentCars')) : {});
  }

  let userGender = '';
  if(currentUser) {
    userGender = intl.formatMessage({id: "generic.forms.form_options.user_title." + currentUser.gender});
  }

  useEffect(() => {
    if (currentCars != null) {
      if(1 === currentCars.length) {
        currentCars.forEach(currentCar => {
          if (currentCar[0].isLeasePlan) {
            setIsLeasePlan(true);
          }
        })
      }
      if (true === currentCars.isLeasePlan) {
        setIsLeasePlan(true);
      }
    }
  },[]);

  const toggleMenu = () => {
    setMobileToggleMenu(!mobileToggleMenu)
  };

  const offMenu = () => {
    setMobileToggleMenu(false)
  };

  const getCars = () =>  {
    let table = []

    if (typeof window !== 'undefined') {
      if (JSON.parse(localStorage.getItem('currentCars'))) {
        for (let i = 0; i < JSON.parse(localStorage.getItem('currentCars')).length; i++) {
          table.push(<li key={i} className="mb-0 py-3 w-100"><Link to="/mycars/my-cars" state={{id: JSON.parse(localStorage.getItem('currentCars'))[i][0].id}}  onClick={offMenu.bind()}>
            <span className="icon">
              <ReactImageFallback
                src={'/images/vehicle/'+JSON.parse(localStorage.getItem('currentCars'))[i][1].modelId+'.png'}
                fallbackImage={BlackGenericVehicle}
                alt={JSON.parse(localStorage.getItem('currentCars'))[i][1].name}
                className="img-fluid"
                width="50" />
            </span>
            <h3 className="mb-0">{JSON.parse(localStorage.getItem('currentCars'))[i][1].name}</h3>
            <p className="mb-0">{JSON.parse(localStorage.getItem('currentCars'))[i][0].licencePlate}</p></Link></li>)
        }
      }
    }

    return table

  }

  const getPopIn = () => {
    if (currentUser.countOfEmailToRead > 0) {
      return (
        <span className="icon-inbox" data-notif={currentUser.countOfEmailToRead}></span>
      );
    } else {
      return(
        <span className="icon-inbox"></span>
      )
    }
  }

  return(
    <>
      <div>
        {/* Mobile Block */}
        <div className={mobileToggleMenu ? "status-container status-container-open status-bronze white-container mb-0 py-2 px-2 d-lg-none": "status-container status-bronze white-container mb-0 py-2 px-2 d-lg-none"}>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 toggle-menu" onClick={toggleMenu.bind()}>
              <TropheeNameBlock currentUser={currentUser} userGender={userGender} />
              <h4>{currentDealer?.name}</h4>

              <p className="update-info dark-red"><span className="icon-pencil"></span> <strong><Link to="/profile/edit-info" className="dark-red text-underline">{intl.formatMessage({ id: "my_profil.update_information" })}</Link></strong></p>

            </div>
            <div className="px-3 picture">
              <Link to="/mycars/my-cars" state={{ id: typeof window !== 'undefined' ? (JSON.parse(localStorage.getItem('currentCars')) ? (JSON.parse(localStorage.getItem('currentCars'))[0] ? JSON.parse(localStorage.getItem('currentCars'))[0][0].id:""):""):""}}>
                <ReactImageFallback
                    src={PictoUser}
                    alt="home"
                    className="img-fluid"
                    width="50" />
              </Link>
            </div>

          </div>

          <Link to="/profile/settings" className="settings"><span className="icon-settings"></span></Link>

        </div>

        <div className="status-container-after px-3 py-3 d-lg-none">

          {/*<h3 className="text-uppercase">{intl.formatMessage({id: "my_profil.rewards_history"})}</h3>*/}

          {/*<div className="white-container py-2 px-3 mb-3">*/}
          {/*  <RewardBlock currentUser={currentUser} />*/}
          {/*</div>*/}

          <h3 className="text-uppercase">{intl.formatMessage({id: "my_profil.my_dealer.title"})}</h3>

          {currentDealer &&
              <div className="white-container py-2 px-3 mb-3">

                <ul className="list-link-picto">
                  <li className="mb-0 py-3 w-100"><Link to="/profile/my-dealer" state={{id: currentDealer.id}}
                                                        className={currentPage === "my-dealer" ? "current" : ""}><span
                      className="icon-dealer-favorite icon"></span><h3 className="mb-0">{currentDealer.name}</h3></Link>
                  </li>
                </ul>

              </div>
          }

          <h3 className="text-uppercase">{intl.formatMessage({id: "my_profil.my_vehicles"})}</h3>

          <div className="white-container py-0 px-3 mb-3">

            <ul className="list-link-picto">
              { getCars() }

              <li className="mb-0 py-3 w-100"><Link to="/mycars/add-car"><span className="icon icon-car icon-car-new"></span>
                <h3 className="mb-0">{intl.formatMessage({id: "my_profil.add_new_vehicle"})}</h3><p className="mb-0"></p></Link>
              </li>
            </ul>

          </div>

        </div>

        {/* Desktop Block */}
        { !mobileToggleMenu &&
        <div className="menu-bar mb-lg-4">
          <div className="d-none d-lg-flex align-items-center pl-4">
            <Link to="/profile/edit-info"><img src={logoYouPlus} alt="Nissan NOW" className="logo" /></Link>
          </div>

          <ul className="menu">
            <li>
              <Link to="/maintenance/timeline" className={currentPage === "timeline" ? "current" : ""}>
                <span className="icon-calendar-maintenance"></span><br />
                <span className="text">{intl.formatMessage({id: "header_menu.timeline"})}</span>
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Link to="/rewards/reward-list" className={currentPage === "reward" ? "current" : ""}>*/}
            {/*    <span className="icon-reward"></span><br />*/}
            {/*    <span className="text">{intl.formatMessage({id: "header_menu.rewards"})}</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li>
              <Link to="/offers/offers-list" className={currentPage === "offers" ? "current" : ""}>
                <span className="icon-offers"></span><br/>
                <span className="text">{intl.formatMessage({id: "header_menu.offers"})}</span>
              </Link>
            </li>
            <li className="inbox-menu">
              <Link to="/inbox/inbox-list" className={currentPage === "inbox" ? "current" : ""}>
                {getPopIn()}<br/>
                <span className="text">{intl.formatMessage({id: "header_menu.inbox"})}</span>
              </Link>
            </li>
          </ul>
        </div>
        }
        <div className="row">
          {/* Desktop Block */}
          <div className="col-lg-4 d-none d-lg-block">
            <div className="status-container status-bronze white-container py-3 px-3 mb-3">
              <Link to="/profile/settings" className="parameter-link"><span className="icon-settings"></span></Link>
              <TropheeNameBlock currentUser={currentUser} userGender={userGender} />
              <p className="dark-red"><span className="icon-pencil"></span> <strong><Link to="/profile/edit-info"><span className="dark-red text-underline">{intl.formatMessage({id: "my_profil.update_information"})}</span></Link></strong></p>

              {/*<hr />*/}

              {/*<h3 className="mb-2">{intl.formatMessage({id: "my_profil.rewards_history"})}</h3>*/}

              {/*<RewardBlock currentUser={currentUser} />*/}

            </div>

            <h3 className="text-uppercase">{intl.formatMessage({id: "my_profil.my_dealer.title"})}</h3>

            {currentDealer &&
                <div className="white-container py-2 px-3 mb-3">

                  <ul className="list-link-picto">
                    <li className="mb-0 py-3 w-100"><Link to="/profile/my-dealer" state={{id: currentDealer.id}}
                                                          className={currentPage === "my-dealer" ? "current" : ""}><span
                        className="icon-dealer-favorite icon"></span><h3 className="mb-0">{currentDealer.name}</h3>
                    </Link></li>
                  </ul>

                </div>
            }

            <h3 className="text-uppercase">{intl.formatMessage({id: "my_profil.my_vehicles"})}</h3>

            <div className="white-container py-0 px-3 mb-3">

              <ul className="list-link-picto">
                { getCars()}

                <li className="mb-0 py-3 w-100"><Link to="/mycars/add-car"><span className="icon icon-car icon-car-new"></span>
                  <h3 className="mb-0">{intl.formatMessage({id: "my_profil.add_new_vehicle"})}</h3><p className="mb-0"></p></Link>
                </li>
              </ul>

            </div>

          </div>

          { !mobileToggleMenu && children}
        </div>
      </div>
    </>
  )
}

MaintenanceLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MaintenanceLayout


