import React, { useEffect, useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import imgVehicle from "../../assets/images/vehicle/JUKE_F15-A1-min.png"
import GenericVehicle from "../../assets/images/logo-nissan-blanc.png"
import ReactImageFallback from "react-image-fallback";

import { useIntl, Link, FormattedMessage, navigate } from "gatsby-plugin-intl"
import axios from "../../components/api/httpclient";
import moment from "../maintenance/timeline";
import { FormattedDate } from "react-intl";
import appli from "../../assets/images/appli.png";
import appstore from "../../assets/images/appstore.png";
import google from "../../assets/images/google-play.png";

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const useForceUpdate = () => useState()[1];

const ServicesDoc = ({ location }) => {
  const intl = useIntl();
  const [car, setCar] = useState({});
  const [model, setModel] = useState({});
  const [documents, setDocuments] = useState([]);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (location.state) {
      axios.get(`/cars/` + location.state.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then(function (response) {
          axios.get(response.data.carModel,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
              }
            })
            .then((m) => {
              if (m.status === 200) {
                if (typeof window !== 'undefined') {
                  localStorage.setItem('car', JSON.stringify({ car: response.data, model: m.data }));

                  setCar(response.data);
                  setModel(m.data);
                  forceUpdate();

                  axios.get("/service_documents/language/" + intl.locale + "/" + m.data.id,
                    {
                      headers: {
                        'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                      }
                    })
                    .then((doc_resp) => {
                      setDocuments(doc_resp.data.documents);

                    }).catch((error) => {
                      console.log('error', error);
                    });

                }
              }

            }).catch((error) => {
              console.log('my-cars model error', error)
            });
        }).catch((error) => {
          console.log('my-cars car error', error)
        });
    }
    else {
      navigate('/maintenance/timeline');
    }
  }, [location.state])

  const getDocuments = () => {
    if (documents && documents.length > 0 && (model.hasOwnProperty('name') || model.hasOwnProperty('title') ) ) {
      return (
        <div className="row">

          {documents.filter(function (document) {
            return (document.model !== null || model.name.toLowerCase().includes(document.model.toLowerCase()))
          }).map(document => (
            <div className="col-md-6 mb-4 d-flex" >
              <div className="item-documentation p-3 pr-4 d-flex">
                <img src={document.thumbnail ? document.thumbnail : "https://via.placeholder.com/176"} width="88" alt={document.description ? document.description : ""} className="mr-3 align-self-start" />
                <div>
                  <h3 className="mb-2">{document.title}</h3>
                  <p className="mb-0 fs-13">{document.description ? document.description : ""}</p>

                  <a href={document.file} target="_blank" className="download fs-13">
                    {intl.formatMessage({ id: "generic.car_informations.service_documentation_button" })}<span className="icon-download"></span>
                  </a>
                </div>
              </div>
            </div>
          )
          )}

        </div>
      );
    }
  };

  const pageContent = (
    <div className="col-lg-8">
      <Link to="/mycars/my-cars" state={{ id: location.state ? location.state.id : "" }} className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span><strong><FormattedMessage id="generic.nav_buttons.back_button" /></strong></Link>

      <div className="header-vehicle d-md-flex">

        <div className="flex-md-grow-1 align-items-center d-md-flex text-center text-md-left">
          <ReactImageFallback
            src={model.image}
            fallbackImage={GenericVehicle}
            alt={model.name}
            className="img-fluid mr-md-4"
            width="140" />
          <div>
            <h1 className="mb-2">{model.name}</h1>
            <div className="fs-20">{car.licencePlate}</div>
          </div>
        </div>

      </div>


      <div className="white-container">
        <div className="d-md-flex justify-content-around align-items-center">

          <div className="d-flex justify-content-around align-items-center px-4 px-md-0 mb-4 mb-md-0">
            <div className="mr-4 mr-md-3">
              <img src={appli} alt="logo appli" className="" width="65" />
            </div>
            <div className="pr-5">
              <h4 className="mb-0">Guide du conducteur</h4>
              <p>Le guide du conducteur Nissan est une application basée sur la technologie de réalité augmentée.
                Elle vous permet de mieux connaître et de mieux comprendre votre véhicule.
              </p>
            </div>
          </div>
          <div className="d-none d-md-block chevron">

          </div>
          <div className="d-flex justify-content-around align-items-center">
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.nissan.alldriverguide&hl=fr&gl=US" target="_blank">
                <img src={google} alt="google play" className="" height="45" />
              </a>
            </div>
            <div className="mx-md-1">
              <p>ou</p>
            </div>
            <div>
              <a href="https://apps.apple.com/fr/app/nissan-drivers-guide/id913672032" target="_blank">
                <img src={appstore} alt="appstore" className="" height="45" />
              </a>
            </div>
          </div>
        </div>

        {getDocuments()}
      </div>
    </div>
  )


  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.mycars.services-documentation" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent} currentPage="my-cars" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )

}

export default ServicesDoc
