import React, {useEffect, useState} from "react"

import {Link} from "gatsby-plugin-intl";
import {FormattedMessage} from "react-intl";
import axios from "../../components/api/httpclient";

const MyDealer = ({location, navigateTo}) => {
  const [dealer, setDealer] = useState({});

  useEffect(() => {
      setDealer(JSON.parse(localStorage.getItem("currentDealer")));
    if (location.state) {
      axios.get(`/dealers/` + location.state.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then(function (response) {
          setDealer(response.data);
        });
    }
  }, []);

  return (

    <div className="white-container mb-4">

      <h1 className="text-uppercase with-border-top with-border-bottom fs-20"><FormattedMessage id="my_profil.my_dealer.title"/></h1>

      <h2 className="text-uppercase text-center mb-0 py-4 bg-darker-grey white"><span className="icon-dealer fs-30 align-middle mr-2"></span>{dealer.name ? dealer.name : ''}</h2>

      <ul className="list-link-picto light-grey-simple-border mb-4 d-md-flex flex-wrap">
        {dealer.phone &&
        (
          <li className="mb-0 py-4 col-md-6 d-md-flex align-items-center border-right-md"><a href="tel:3228932904"><span className="icon-phone icon dark-red"></span><h3 className="mb-0">{dealer.phone ? dealer.phone : ''}</h3></a></li>
        )
        }

        {dealer.address &&
        (
          <li className="mb-0 py-4 col-md-6"><a href={encodeURI("https://www.google.com/maps/search/"+dealer.name+' '+ dealer.address+' '+dealer.postcode+' '+dealer.city)} target="_blank"><span className="icon-pin-marker icon dark-red"></span><h3 className="mb-0">{dealer.address ? dealer.address : ''}<br />{dealer.postcode ? dealer.postcode : ''} {dealer.city ? dealer.city : ''}</h3></a></li>
        )
        }

        {dealer.website &&
        (
          <li className="mb-0 py-4 col-md-6 d-md-flex align-items-center border-right-md no-border-bottom-md"><a href={dealer.website ? dealer.website : '#'} target="_blank"><span className="icon-globe icon dark-red fs-20"></span><h3 className="mb-0"><FormattedMessage id="my_profil.my_dealer.website"/></h3></a></li>
        )
        }

        {dealer.fbPage &&
        (
          <li className="mb-0 py-4 col-md-6"><a href={dealer.fbPage ? dealer.fbPage : '#'} target="_blank"><span className="icon-facebook icon dark-red fs-20"></span><h3 className="mb-0"><FormattedMessage id="my_profil.my_dealer.facebook"/></h3></a></li>
        )
        }
      </ul>

      <div className="d-flex">
          {
             dealer.bookingUrl &&
            (
                <div className="d-flex links-contact-go me-4">
                    <a href={dealer.bookingUrl} target="_blank"><span className="icon icon-calendar"></span>Prendre un rendez-vous <span></span></a>
                </div>
            )
          }
        <Link to={navigateTo} className="button red mx-auto mr-lg-0"><span><FormattedMessage id="1_2_2_onboarding_set_dealer_dealer_already.button2"/></span></Link>
      </div>

    </div>
  )
}


export default MyDealer
