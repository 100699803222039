import React, {useState, useEffect} from "react"

import SEO from "../../components/seo"

import instance  from "../../components/api/httpclient"

import moment from "moment";
import { navigate, useIntl, Link } from "gatsby-plugin-intl"
import GenericVehicle from "../../../public/images/vehicle/generic.png";
import ReactImageFallback from "react-image-fallback";

const AddCarAdded = (navigateTo) => {

  const car = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('car')): {car :{}, model:{}} ;
  const [carImage, setCarImage]  =  useState('');
  const intl = useIntl();
    function  setCarImageSource() {
        try{
            const src = require('../../assets/images/vehicle/'+car.car.MODEL_ID+'.png')
            setCarImage(src)
        }
        catch(err){
            setCarImage('')
        }
    }
  function validCar(event, id) {
    // instance.post('/add-car',
    //   {carId: id},
    //   {
    //     headers: {
    //       'Authorization': localStorage.getItem('token')
    //     }
    //   }).then((response) => {
        navigate(navigateTo.navigateTo);
    // });
    // event.preventDefault();
  }

  return (
      <div>
        <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.add-car-added" })}/>

        <h1 className="text-center text-uppercase pt-4 mb-4">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.result_title" })} <span
          className="icon-check green"></span></h1>


        <div className="white-container w-auto mb-4 px-3 py-4 px-sm-4">


          <div className="row">

            <div className="col-md-4 col-xl-3">
              <div className="text-center">
                {/*<img src={car.model.image} alt={car.model.name} className="img-fluid" width="446"/>*/}
                  {'' !== carImage &&
                      (
                          <ReactImageFallback
                              src={'/images/vehicle/'+car.car.MODEL_ID+'.png'}
                              alt={car.model.name}
                              className="img-fluid"
                               />
                      )
                  }
                  {'' === carImage &&
                      (
                          <ReactImageFallback
                              src={GenericVehicle}
                              alt={car.model.name}
                              className="img-fluid"
                              width="120px" />
                      )
                  }
               <br/>
                <h2 className="mb-1">{car.car.MODEL_NAME}</h2>
              </div>
            </div>
            <div className="col-md-8 col-xl-9">
              <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.title" })}</h1>

              <div className="row mb-4">
                <span className="d-inline-block w-50 grey-border align-top">{intl.formatMessage({ id: "generic.forms.form_field_labels.number_plate" })}</span><span
                className="d-inline-block w-50 text align-top text-break"><strong>{car.car.LICENSE_PLATE}</strong></span><br />

                <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.engine" })}</span><span
                className="d-inline-block w-50 text align-middle"><strong>{car.car.ENGINE_TYPE}</strong></span><br />
                {/*<span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.finish" })}</span><span*/}
                {/*className="d-inline-block w-50 text align-middle"><strong>{car.car.grade}</strong></span><br/>*/}
                <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.fuel_type" })}</span><span
                className="d-inline-block w-50 text align-middle"><strong>{(car.car.FUEL === 1 || car.car.FUEL === 2 || car.car.FUEL === 4) ? intl.formatMessage({ id: "generic.fuel_type." + car.car.FUEL }) : " "}</strong></span><br />
                {car.car.FIRST_REGISTRATION_DATE !== undefined &&
                  <span>
                    <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.registration_date" })}</span>
                        <span className="d-inline-block w-50 text align-middle"><strong>{moment(car.car.FIRST_REGISTRATION_DATE, moment.ISO_8601).format('DD/MM/YYYY')}</strong></span><br />
                  </span>
                }

                {/*<span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.last_service_date" })}</span><span*/}
                {/*className="d-inline-block w-50 text align-middle"><strong>{car.car.lastMaintenanceDate ? moment(car.car.lastMaintenanceDate, moment.ISO_8601).format('DD/MM/YYYY') : intl.formatMessage({ id: "generic.mentions.unknown" })}</strong></span><br/>*/}
                <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.mileage" })}</span><span
                className="d-inline-block w-50 text align-middle"><strong>{car.car.mileage || car.car.mileage === 0 ? `${car.car.mileage} KM` : '-'}</strong></span>

              </div>

              <div>
                <Link to="/profile/add-car-kms" className="button red mx-auto mr-lg-0" onClick={(event => validCar(event, car.car.id))}>{intl.formatMessage({ id: "generic.nav_buttons.next_button" })}</Link>
              </div>
            </div>

          </div>

        </div>
      </div>
  );
};

export default AddCarAdded
