import React, {useEffect, useState} from "react"

import SEO from "../../components/seo"
import { Form } from "react-bootstrap"
import moment from "moment";

import instance from "../../components/api/httpclient";
import { navigate, useIntl } from "gatsby-plugin-intl"
import GenericVehicle from  "../../assets/images/logo.png"

const AddCarKms = (navigateTo) => {

  const car = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('car')): {car :{}, model:{}} ;
  const [mileage, setMileage] = useState(car.car.mileage);
  const intl = useIntl();

  const updateMileage = (event) => {
    console.log(event);
    //event = mileage
    if ( notValidMileAge() === false && mileage !== car.car.mileage) {
      console.log(mileage);
      //Créer route pour poste sur le VIN pour mettre à jour le mileage
      instance.patch('/cars/'+car.car.id,
        {
          mileage: parseInt(mileage),
          mileageUpdatedDate: moment().format(),
          updatedDate: moment().format()
        },
        {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': `application/merge-patch+json`
          }
        }).then( (response) => {

        localStorage.setItem('car', JSON.stringify({car : response.data, model : car.model}));

        navigate(navigateTo.navigateTo);

      }).catch( (error) => { console.log( error )})
    } else if (mileage === car.car.mileage) {
      navigate(navigateTo.navigateTo);
    }
    event.preventDefault();
  };

  const notValidMileAge = () =>  {
    if (mileage === 0) {
      return true;
    }
    return false;
  };

  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-")
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-" ;
    }
    else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  return (
      <div>
        <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.add-car-kms" })} />
        <div className="white-container w-auto mb-4 px-3 py-4 px-sm-4">
          <div className="row">

            <div className="col-md-4 col-xl-3">
              <div className="text-center">
                <img  className="img-fluid" width="446"  alt={car.model ? car.model.name : ''} src={car.model.image} onError={(e)=>{e.target.onError = null; e.target.src = GenericVehicle}}/>
              </div>
            </div>
            <div className="col-md-8 col-xl-9">
              <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({id: "1_2_1_4_onboarding_add_car_kms.title"})}</h1>

              <Form>

                <div className="row mb-4">
                  <div className="col-md-6 col-xl-7 mb-4">


                    <div className="input-units mb-4" data-units="KM">
                      <Form.Control type="number" className="" placeholder={mileage} min="0" max="999999" maxLength="6" onKeyDown={ (e) => formatInput(e) } onChange={(e) => setMileage(e.target.value)}/>
                    </div>
                    {notValidMileAge() &&
                    <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({id: "1_2_1_4_onboarding_add_car_kms.title"})}</small>
                    }

                    <p><em>{intl.formatMessage({id: "1_2_1_4_onboarding_add_car_kms.help_text"})}</em></p>

                  </div>
                  <div className="col-md-6 col-xl-5 mb-4">
                    <button type="submit" className={ notValidMileAge() ? "disabled button red w-100 mx-auto" : "button red w-100 mx-auto" } onClick={(event)=> updateMileage(event)}>{intl.formatMessage({id: "generic.nav_buttons.next_button"})}</button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AddCarKms
