import React, {useEffect, useState} from "react"

import SEO from "../../components/seo"

import { Form } from "react-bootstrap"
import {FormattedMessage} from "react-intl";
import instance from "../../components/api/httpclient";
import {navigate, useIntl} from "gatsby-plugin-intl";

const Preferences = (navigateTo) => {
  const intl = useIntl();
  const [reminderChannelNotification, setReminderChannelNotification] = useState(false);
  const [reminderChannelSMS, setReminderChannelSMS] = useState(false);
  const [reminderChannelEmail, setReminderChannelEmail] = useState(false);
  const [reminderChannelMail, setReminderChannelMail] = useState(false);
  const [offerChannelNotification, setOfferChannelNotification] = useState(false);
  const [offerChannelSMS, setOfferChannelSMS] = useState(false);
  const [offerChannelEmail, setOfferChannelEmail] = useState(false);
  const [offerChannelMail, setOfferChannelMail] = useState(false);
  const [phoneID, setPhoneID] = useState('+33');
  const [phone, setPhone] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const [phoneDescriptionOpen, setPhoneDescriptionOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      setCurrentUser(user);
      setReminderChannelNotification(user.reminderChannelNotification);
      setReminderChannelEmail(user.reminderChannelEmail);
      setReminderChannelSMS(user.reminderChannelSMS);
      setReminderChannelMail(user.reminderChannelMail);
      setOfferChannelNotification(user.offerChannelNotification);
      setOfferChannelSMS(user.offerChannelSMS);
      setOfferChannelEmail(user.offerChannelEmail);
      setOfferChannelMail(user.offerChannelMail);

      if (intl.locale === 'fr-BE' || intl.locale === 'nl-BE') {
        setPhoneID('+32')
      }
      else if (intl.locale === 'fr-LU') {
        setPhoneID('+352')
      }
      if(user.phone) {
        setPhone(user.phone.replace(phoneID, ''));
      }
    }
  }, []);

  const sendPreferences = (event) => {
    instance.post('/preferences',
        {
          reminderChannelNotification: reminderChannelNotification,
          reminderChannelSMS: reminderChannelSMS,
          reminderChannelEmail: reminderChannelEmail,
          reminderChannelMail: reminderChannelMail,
          offerChannelNotification: offerChannelNotification,
          offerChannelSMS: offerChannelSMS,
          offerChannelEmail: offerChannelEmail,
          offerChannelMail: offerChannelMail,
          phone: phone ? phoneID+phone : ''
        },
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        }).then(function (response) {
          if (typeof window !== 'undefined' ) {
            localStorage.setItem('currentUser', JSON.stringify(response.data.user));
            navigate(navigateTo.navigateTo);
        }
    });
    event.preventDefault();
  };

  const handleChange = (event) => {
  };

  const isPhoneValid = () => {
    return phone.length === 12;
  };

    return(
        <div>
          <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.preferences" })}/>


          <div className="white-container mb-5">

            <h1 className="with-border-top with-border-bottom text-uppercase"><FormattedMessage id="1_2_3_onboarding_preferences.title"/></h1>

            <Form>

              <h3 className="mb-2"><FormattedMessage id="1_2_3_onboarding_preferences.section_title_1"/></h3>

              <p className="mb-4"><em><FormattedMessage id="1_2_3_onboarding_preferences.section_help_text_1"/></em></p>

              <Form.Group>
                <div className="d-flex flex-wrap">
                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="reminderChannelSMS" name="reminderChannelSMS" checked={reminderChannelSMS} className="form-check-input" onChange={(event) => setReminderChannelSMS(event.target.checked)}/>
                      <label htmlFor="reminderChannelSMS" title="" className="form-check-label"><FormattedMessage id="generic.forms.form_options.notifications.1"/></label>
                    </div>
                  </div>

                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="reminderChannelEmail" name="reminderChannelEmail" checked={reminderChannelEmail} className="form-check-input" onChange={(event) => setReminderChannelEmail(event.target.checked)}/>
                      <label htmlFor="reminderChannelEmail" title="" className="form-check-label"><FormattedMessage id="generic.forms.form_options.notifications.3"/></label>
                    </div>
                  </div>

                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="reminderChannelMail" name="reminderChannelMail" checked={reminderChannelMail} className="form-check-input" onChange={(event) => setReminderChannelMail(event.target.checked)}/>
                      <label htmlFor="reminderChannelMail" title="" className="form-check-label">Courrier</label>
                    </div>
                  </div>

                </div>
              </Form.Group>


              <h3 className="mb-2"><FormattedMessage id="1_2_3_onboarding_preferences.section_title_2"/></h3>

              <p className="mb-4"><em><FormattedMessage id="1_2_3_onboarding_preferences.section_help_text_2"/></em></p>

              <Form.Group>
                <div className="d-flex flex-wrap">
                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="offerChannelSMS" name="offerChannelSMS" checked={offerChannelSMS} className="form-check-input" onChange={(event) => setOfferChannelSMS(event.target.checked)}/>
                      <label htmlFor="offerChannelSMS" title="" className="form-check-label"><FormattedMessage id="generic.forms.form_options.notifications.1"/></label>
                    </div>
                  </div>

                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="offerChannelEmail" name="offerChannelEmail" checked={offerChannelEmail} className="form-check-input" onChange={(event) => setOfferChannelEmail(event.target.checked)}/>
                      <label htmlFor="offerChannelEmail" title="" className="form-check-label"><FormattedMessage id="generic.forms.form_options.notifications.3"/></label>
                    </div>
                  </div>

                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="offerChannelMail" name="offerChannelMail" checked={offerChannelMail} className="form-check-input" onChange={(event) => setOfferChannelMail(event.target.checked)}/>
                      <label htmlFor="offerChannelMail" title="" className="form-check-label">Courrier</label>
                    </div>
                  </div>


                </div>
              </Form.Group>

              <Form.Group>

                <Form.Label className="no-transform"><FormattedMessage id="1_2_3_onboarding_preferences.section_title_3"/></Form.Label> <span className={ isPhoneValid() ? "icon-check ml-2": "ml-2"}></span><br/>
                <div className="phone-number d-sm-inline-block" data-indicatif={phoneID}>
                  <Form.Control type="text" name ="phone" placeholder="_-__-__-__-__" pattern="^\d{10}$" maxLength="10" value={phone} onChange={(event) => setPhone(event.target.value) }/>
                </div>
                <div className="position-relative">
                  <small className="form-text"><span className="birth-info" onClick={() => setPhoneDescriptionOpen(!phoneDescriptionOpen)}>
                {intl.formatMessage({ id: "generic.forms.form_mentions.phone_number" })}</span></small>
                  {(() => {
                    if(phoneDescriptionOpen) {
                      return (
                        <div className="info-bulle">
                          {intl.formatMessage({ id: "1_2_3_onboarding_preferences.section_help_text_3" })}
                        </div>
                      )
                    }
                  })()}
                </div>

              </Form.Group>

              <Form.Group className="mb-0">
                <button type="submit"
                        className={ ( (!reminderChannelSMS && !offerChannelSMS) || (( (reminderChannelSMS || offerChannelSMS) && isPhoneValid()))  ) ? "button red w-100 mx-auto" : " disabled button red w-100 mx-auto" }
                        onClick={((event) => sendPreferences(event))}
                        disabled={!( ( (!reminderChannelSMS && !offerChannelSMS) || (( (reminderChannelSMS || offerChannelSMS) && isPhoneValid()))  ))}>
                  <FormattedMessage id="generic.forms.form_buttons.finish"/>
                </button>
              </Form.Group>

            </Form>

          </div>


          <p className={showMoreOpen ? "show-more open" : "show-more"}>
            <FormattedMessage id="generic.forms.form_mentions.gdpr_text" />
          </p>

          <div className="text-center mb-4">
            <div className="btn-show-more" onClick={() => setShowMoreOpen(!showMoreOpen)}><FormattedMessage id="generic.forms.form_buttons.readmore" /> v</div>
          </div>

        </div>
    )
};

export default Preferences
