import React, {useState, useEffect} from "react"

import SEO from "../../components/seo"
import { Form } from "react-bootstrap"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import instance from "../../components/api/httpclient";
import moment from "moment";
import { navigate, useIntl } from "gatsby-plugin-intl"

import classNames from 'classnames/bind';
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import GenericVehicle from  "../../assets/images/logo.png"


const AddCarLastService = (navigateTo) => {

  const car = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('car')): {car :{}, model:{}} ;
  const [updatedDate, setUpdateDate] = useState(car.car.lastMaintenanceDate);
  const [datepickerDate, setDatepickerDate] = useState();
  const intl = useIntl();

  const datepickerClass = classNames({
    'button white': true,
    'multiline-button': datepickerDate
  });
  const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']
  const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
  const locale = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  }
  useEffect((event) => {
    if ( intl.locale === 'fr-BE' || intl.locale=== 'fr-LU') {
      registerLocale('locale', fr)
    } else {
      registerLocale('locale', nl)
    }
  },[]);

  function updateLastMaintenanceDate(event, date) {
    if (!notValidLastService() && date !== car.car.lastMaintenanceDate) {
      instance.patch('/cars/'+car.car.id,
        {
          lastMaintenanceDate: date,
          lastMaintenanceUpdatedDate: moment().format(),
          updatedDate: moment().format()
        },
        {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': `application/merge-patch+json`
          }
        }).then( (response) => {
        localStorage.removeItem('car');
        instance.get("/me",
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then( (res) => {
              console.log('response', res)
              if (res.status === 200) {
                if (typeof window !== 'undefined') {
                  localStorage.setItem('currentUser', JSON.stringify(res.data.user));

                  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};
                }

                // If the user has already a dealer
                if (res.data.user.dealer == null) {
                  callCarApi(res);
                  navigate(navigateTo.navigateTo);
                } else {

                  // Dealer
                  instance.get(res.data.user.dealer,
                    {
                      headers: {
                        'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                      }
                    })
                    .then((r) => {
                      if (r.status === 200) {
                        if (typeof window !== 'undefined') {
                          localStorage.setItem('currentDealer', JSON.stringify({
                            id: r.data.id,
                            name: r.data.name
                          }));

                          callCarApi(res);

                          navigate('/maintenance/timeline');
                        }
                      }
                    }).catch((error) => {
                    console.log('error', error)
                  });
                }
              } else if (res.status === 401) {
                navigate('/sessions/logout');
              }
            }
          ).catch( (error) => {
            console.log('error', error);
          }
        );

      }).catch( (error) => { console.log( error )})
    } else if (date === car.car.lastMaintenanceDate) {
      navigate(navigateTo.navigateTo, {
        state: {
          id: car.car.id
        }}
        );
    }
    event.preventDefault();
  }

  const callCarApi = (res) => {
    const nbCars = res.data.user.cars.length;

    // Cars
    for (var i = 0; i < nbCars; i++) {
      instance.get(res.data.user.cars[i],
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then((c) => {
          if (c.status === 200) {
            if (typeof window !== 'undefined') {
              instance.get(c.data.carModel,
                {
                  headers: {
                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                  }
                })
                .then((m) => {
                  if (m.status === 200) {
                    if (typeof window !== 'undefined') {

                      localStorage.setItem('currentCars', JSON.stringify({
                        id: c.data.id,
                        licencePlate: c.data.licencePlate,
                        model: m.data.name,
                        image: m.data.image,
                        isLeasePlan: c.data.isLeasePlan
                      }));

                      console.log(localStorage.getItem('currentCars'));

                    }
                  }

                }).catch((error) => {
                console.log('error', error)
              });


            }
          } else if (c.status === 401) {
            navigate('/sessions/logout');
          }

        }).catch((error) => {
        console.log('error', error)
      });

      console.log(localStorage.getItem('currentCars'));
    }
}

  const notValidLastService = () =>  {
    if (updatedDate === '') {
      return true;
    }
    return false;
  }

  const onRadioButtonChange = (e) => {
    setUpdateDate(e.target.value)
    setDatepickerDate();
  }

  const onDatePickerChange = (date) => {
    setUpdateDate(date);
    setDatepickerDate(date);
    // Clear all selected radio button
    var ele = document.getElementsByName("latest-maintenance");
    for(var i=0;i<ele.length;i++) {
      ele[i].checked = false;
    }
  }

  return(
      <div>
        <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.add-car-last-service" })}/>

        <div className="white-container w-auto mb-4 px-3 py-4 px-sm-4">
          <div className="row">

            <div className="col-md-4 col-xl-3">
              <div className="text-center">
                <img  className="img-fluid" width="446"  alt={car.model ? car.model.name : ''} src={car.model.image} onError={(e)=>{e.target.onError = null; e.target.src = GenericVehicle}}/>
              </div>
            </div>
            <div className="col-md-8 col-xl-9">
              <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({id: "1_2_1_4_onboarding_add_car_last_service.title"})}</h1>

              <p>{intl.formatMessage({id: "1_2_1_4_onboarding_add_car_last_service.help_text"})}</p>

              <Form>

                <div className="row mb-4">

                  <div className="col-lg-6 mb-2">
                    <div className="radio-date">
                      <Form.Control type="radio" name="latest-maintenance" id="latest-maintenance-7"
                                    onClick={((e) => onRadioButtonChange(e))}
                                    value="1970-01-01T00:00:00+01:00"/> <label htmlFor="latest-maintenance-7"
                                                                                         className="button white"><span>{intl.formatMessage({id: "generic.forms.form_options.last_maintenance.7"})}</span></label>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-2">
                    <div className="radio-date">
                      <Form.Control type="radio" name="latest-maintenance" id="latest-maintenance-1"
                                    onClick={((e) => onRadioButtonChange(e))}
                                    value={moment().add(-1, 'months').format()}/> <label htmlFor="latest-maintenance-1"
                                                                                         className="button white"><span>{intl.formatMessage({id: "generic.forms.form_options.last_maintenance.1"})}</span></label>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="radio-date">
                      <Form.Control type="radio" name="latest-maintenance" id="latest-maintenance-2"
                                    onClick={((e) => onRadioButtonChange(e))}
                                    value={moment().add(-3, 'months').format()}/> <label htmlFor="latest-maintenance-2"
                                                                                         className="button white"><span>{intl.formatMessage({id: "generic.forms.form_options.last_maintenance.2"})}</span></label>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="radio-date">
                      <Form.Control type="radio" name="latest-maintenance" id="latest-maintenance-3"
                                    onClick={((e) => onRadioButtonChange(e))}
                                    value={moment().add(-6, 'months').format()}/> <label htmlFor="latest-maintenance-3"
                                                                                         className="button white"><span>{intl.formatMessage({id: "generic.forms.form_options.last_maintenance.3"})}</span></label>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="radio-date">
                      <Form.Control type="radio" name="latest-maintenance" id="latest-maintenance-4"
                                    onClick={((e) => onRadioButtonChange(e))}
                                    value={moment().add(-1, 'years').format()}/> <label htmlFor="latest-maintenance-4"
                                                                                        className="button white"><span>{intl.formatMessage({id: "generic.forms.form_options.last_maintenance.4"})}</span></label>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="radio-date">
                      <Form.Control type="radio" name="latest-maintenance" id="latest-maintenance-5"
                                    onClick={((e) => onRadioButtonChange(e))}
                                    value={moment().add(-2, 'years').format()}/> <label htmlFor="latest-maintenance-5"
                                                                                        className="button white"><span>{intl.formatMessage({id: "generic.forms.form_options.last_maintenance.5"})}</span></label>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="radio-date">
                      <label htmlFor="latest-maintenance-6" className={datepickerClass}><span>
                        {intl.formatMessage({id: "generic.forms.form_options.last_maintenance.6"})}<br/>
                        {datepickerDate ? moment(datepickerDate).format("DD/MM/YYYY") : ""}</span>
                      </label>
                      <DatePicker className="form-control" dateFormat="dd/MM/yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  locale={locale}
                                  maxDate={new Date()}
                                  selected={updatedDate ? new Date(updatedDate) : new Date()}
                                  onChange={((date) => {
                                    onDatePickerChange(date);
                                  })}/>
                    </div>
                  </div>

                  <div className="col-lg-6 offset-lg-6 mb-2 radio-date">
                    <button type="submit" className={ notValidLastService() ? "disabled button red w-100" : "button red w-100" } onClick={(event) => updateLastMaintenanceDate(event, updatedDate)}>{intl.formatMessage({id: "generic.nav_buttons.next_button"})}</button>
                  </div>

                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>
  )
}

export default AddCarLastService
