import React, {useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import moment from "moment";
import {useIntl, Link, navigate} from "gatsby-plugin-intl"
import {Form} from "react-bootstrap";
import instance from "../../components/api/httpclient";

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const ChangePassword = () => {
  const intl = useIntl();
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};
  const [isPasswordVisibleOld, setIsPasswordVisibleOld] = useState(false);
  const [errorPasswordOld, setErrorPasswordOld] = useState(false);
  const [passwordOld, setPasswordOld] = useState('');

  const [isPasswordVisibleNew, setIsPasswordVisibleNew] = useState(false);
  const [errorPasswordNew, setErrorPasswordNew] = useState(false);
  const [passwordNew, setPasswordNew] = useState('');

  /* Toggle password visibility */
  const togglePwdOld = () => {
    setIsPasswordVisibleOld(!isPasswordVisibleOld);
  };

  /* Toggle password visibility */
  const togglePwdNew = () => {
    setIsPasswordVisibleNew(!isPasswordVisibleNew);
  };

  const validatePassword = (password) => {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)/i;
    return re.test(password);
  }

  const handleClick = (event) => {

    // Check if passwordNew is empty
    if(passwordNew === "" || !validatePassword(passwordNew)) {
      setErrorPasswordNew(true);
    }

    // Check if password is empty
    if (passwordOld === "") {
      setErrorPasswordOld(true);
    }

    if(!errorPasswordOld && !errorPasswordNew && validatePassword(passwordNew)) {
      instance.post('/check-password',
        {
          email: currentUser.email,
          password: passwordOld
        },
        {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        }).then(function (response) {

        if (response.status === 200) {

          if (response.data.valid === true) {

            if (!errorPasswordOld && !errorPasswordNew && validatePassword(passwordNew)) {

              instance.put(`/users/` + currentUser.id,
                {
                  password: passwordNew
                },
                {
                  headers: {
                    'Authorization': localStorage.getItem('token')
                  }
                }).then(function (res) {



                // UPDATING LOCALSTORAGE
                instance.get("/me",
                  {
                    headers: {
                      'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                    }
                  })
                  .then((r) => {
                    if (typeof window !== 'undefined') {
                      localStorage.setItem('currentUser', JSON.stringify(r.data.user));

                      navigate('/profile/edit-info');

                    }
                  });
              });
            }
          }
          else {
            setErrorPasswordOld(true);
          }

        } else if (response.status === 404) {
          console.log('error', '404');
        } else if (response.status === 401) {
          navigate('/sessions/logout');
        }
      }).catch((error) => {
          console.log(error)
          console.log('Unable to check')
        }
      );
    }

  };

  const pageContent = () => {
    return(
      <div className="col-lg-8">
        <div className="mobile-container">
          <Link to="/profile/edit-info" className="black mb-4 mt-0 back-link"><span className="icon icon-chevron"></span><strong>{intl.formatMessage({id: "generic.nav_buttons.back_button"})}</strong></Link>
        </div>
        <div className="white-container">

          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({id: "generic.forms.form_field_labels.edit_my_password"})}</h1>


          <div className="row">

            <div className="col-md-8 mb-4">

              <div className="form-group">
                <Form.Group>
                  <Form.Label className="required">{intl.formatMessage({id: "generic.forms.form_field_labels.old_password"})}</Form.Label>
                  <div className="password-wrapper">
                    {isPasswordVisibleOld ?
                      <input
                        className={errorPasswordOld ? "error form-control" : "form-control"}
                        type='text'
                        onChange={
                          (e) => {
                            setPasswordOld(e.target.value);
                            e.target.value === "" ? setErrorPasswordOld(true) : setErrorPasswordOld(false);
                          }
                        }
                        value={passwordOld}
                      />
                      :
                      <input
                        className={errorPasswordOld ? "error form-control" : "form-control"}
                        type='password'
                        onChange={
                          (e) => {
                            setPasswordOld(e.target.value);
                            e.target.value == "" ? setErrorPasswordOld(true) : setErrorPasswordOld(false);
                          }
                        }
                        value={passwordOld}
                      />}

                    <span className={isPasswordVisibleOld ? "icon-eye toggle-pwd grey-border" : "icon-eye toggle-pwd"} onClick={togglePwdOld.bind()}></span>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="required">{intl.formatMessage({id: "generic.forms.form_field_labels.new_password"})}</Form.Label>
                  <div className="password-wrapper">
                    {isPasswordVisibleNew ?
                      <input
                        className={errorPasswordNew ? "error form-control" : "form-control"}
                        type='text'
                        onChange={
                          (e) => {
                            setPasswordNew(e.target.value);
                            e.target.value === "" ? setErrorPasswordNew(true) : setErrorPasswordNew(false);
                          }
                        }
                        value={passwordNew}
                      />
                      :
                      <input
                        className={errorPasswordNew ? "error form-control" : "form-control"}
                        type='password'
                        onChange={
                          (e) => {
                            setPasswordNew(e.target.value);
                            e.target.value == "" ? setErrorPasswordNew(true) : setErrorPasswordNew(false);
                          }
                        }
                        value={passwordNew}
                      />}

                    <span className={isPasswordVisibleNew ? "icon-eye toggle-pwd grey-border" : "icon-eye toggle-pwd"} onClick={togglePwdNew.bind()}></span>
                  </div>
                </Form.Group>
              </div>
              {errorPasswordOld ?

                <div className="col-md-8">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>Sorry, the old password you entered is incorrect</small>
                  </Form.Group>
                </div>
              :
                ""
              }
              {errorPasswordNew ?

                <div className="col-md-8">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>Please choose another password.<br/>
                      At least 1 capital letter<br/>
                      At least 1 lowercase letter<br/>
                      At least 1 number<br/>
                      At least 8 characters
                    </small>
                  </Form.Group>
                </div>
                :
                ""
              }

              <div className="form-group">
                <div className="button red mx-auto mr-md-0" onClick={(event) => handleClick(event)} ><span>{intl.formatMessage({ id: "generic.forms.form_field_labels.edit_my_password" })}</span></div>

              </div>

            </div>

          </div>

        </div>
      </div>
    )
  }

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.change-password" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="change-password" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default ChangePassword
