import React, {useEffect} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import instance from "../../components/api/httpclient";

const Congratulations = () => {
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};
  const intl = useIntl();

  const statusColor = (() => {
    switch(currentUser.status) {
      case 1:
        return 'bronze';
      case 2:
        return 'silver';
      case 3:
        return 'gold';
      case 4:
        return 'platinium';
    }
  })()

  useEffect(() => {
    instance.post('/welcome/email',
      {
        email: currentUser.email,
        lang: intl.locale
      },
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      }).then(function (response) {

    });

  },[]);

  return (
    <Layout>

      <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.congratulations" })} />

      <div className="white-container mb-5">
      <Link to="/home" className="d-none">HOME</Link>
        <h1 className="text-center mb-3 mt-0">
          {intl.formatMessage({ id: "1_2_4_onboarding_get_bronze.title" })}<br/>
          {currentUser.firstName} {currentUser.particleName} {currentUser.lastName} !
        </h1>
        <div className="text-center mb-4">
          <span className="icon-check fs-40 green"/>
        </div>
        <h2 className="text-center mb-4">{intl.formatMessage({ id: "1_2_4_onboarding_get_bronze.intro" })}</h2>

        <p className="text-center mb-4">{intl.formatMessage({ id: "1_2_4_onboarding_get_bronze.description" })}</p>


        <div className="mb-3">
          <Link to="/maintenance/timeline" className="button red mx-auto">{intl.formatMessage({ id: "generic.nav_buttons.go_button" })}</Link>
        </div>

      </div>

    </Layout>
  )
}

export default Congratulations
